import React, { useEffect } from 'react';
import { Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import Lottie from 'react-lottie';
import PageNotFoundAnimation from '../../assets/lotties/404.json';
import ServiceUnavailable from '../../assets/lotties/503.json';
import { StatusRouteType } from '../../interfaces/App';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

interface StatusProps {
  status: StatusRouteType;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    header: {
      padding: 10,
      float: 'right',
    },
    link: {
      textDecoration: 'none',
    },
    lottie: {
      textAlign: 'center',
    },
  }),
);

export default function StatusPage(props: StatusProps) {
  const { status } = props;

  const classes = useStyles();

  const animationData = {
    404: {
      width: '100%',
      height: '100%',
      lottie: PageNotFoundAnimation,
    },
    503: {
      width: '72%',
      height: '72%',
      lottie: ServiceUnavailable,
    },
  };

  const options = {
    loop: true,
    autoplay: true,
    animationData: animationData[status].lottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Link to="login" className={classes.link}>
          <Button color="primary" variant="contained" startIcon={<ArrowBackIcon />}>
            Voltar para o sistema
          </Button>
        </Link>
      </div>

      <div className={classes.lottie}>
        <Lottie
          options={options}
          height={animationData[status].height}
          width={animationData[status].width}
        />

        {status === 503 && <Typography variant="h2">Serviço Indisponível</Typography>}
      </div>
    </div>
  );
}
