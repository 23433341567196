import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Props } from './props';
import { useAuthContext } from '../../contexts/AuthContext';

const RouteOperator = (props: Props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  const { isLoggedIn } = useAuthContext();

  return isLoggedIn ? (
    <Route
      exact
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  );
};

export default RouteOperator;
