import React, { useState } from 'react';
import { Button, createStyles, makeStyles, Theme, CircularProgress } from '@material-ui/core';
import { colors } from '../../../../../../variables/Colors';
import DefineTasks from '../../DefineTasks/DefineTasks';
import { DataTasks } from '../../DefineTasks/interfaces';
import { classGeneric } from '../../../../../../variables/Styles';
import OperatorService from '../../../../../../services/OperatorService';
import ValidationService from '../../../../../../services/ValidationService';
import { useLoader } from '../../../../../../contexts/LoaderContext';
import { useOperator } from '../../../OperatorContext';
import ProjectUserOperatorService from '../../../../../../services/ProjectUserOperatorService';
import { Operator } from '../../../../../../interfaces/Operator';
import { DataInfo } from './StepDataOperator';

interface StepTasksProps {
  dataOperator: Operator;
  handleBack: () => void;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defineTasks: {
      marginTop: 10,
      marginBottom: 30,
    },
  }),
);

export default function StepTasks(props: StepTasksProps): JSX.Element {
  const { dataOperator, handleBack, handleClose } = props;
  const classes = useStyles();
  const classesGeneric = classGeneric();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataTasks, setDataTasks] = useState<DataTasks>({} as DataTasks);
  const { triggerAlertMessage } = useLoader();
  const { changeDataOperators, dataOperators, initialDataTask } = useOperator();

  const setDataCallback = (data: DataTasks) => {
    setDataTasks(data);
  };

  const handleSave = () => {
    setLoading(true);
    if (dataOperator) {
      OperatorService.save(dataOperator).subscribe(
        operator => {
          changeDataOperators([...dataOperators, operator]);
          saveProjectsUserOperator(operator.id);
        },
        error => {
          ValidationService.validateBackEndAlert(error, triggerAlertMessage, 'Operador');
          setLoading(false);
        },
      );
    }
  };

  const saveProjectsUserOperator = (idOperator: string | number) => {
    const projectsOperations = ProjectUserOperatorService.prepareDataDefinedTasks(
      dataTasks,
      idOperator,
    );
    projectsOperations.forEach((project, index) => {
      ProjectUserOperatorService.save(project).subscribe(
        response => {
          if (index + 1 === projectsOperations.length) successRegister();
        },
        error => {
          ValidationService.validateBackEndAlert(error, triggerAlertMessage);
          setLoading(false);
        },
      );
    });
    if (!projectsOperations.length) successRegister();
  };

  const successRegister = () => {
    handleClose();
    triggerAlertMessage({
      type: 'success',
      msg: 'Operador cadastrado com sucesso',
    });
    setLoading(false);
  };

  return (
    <div>
      <div className={classes.defineTasks}>
        {initialDataTask && (
          <DefineTasks dataTasks={initialDataTask} setDataCallback={setDataCallback} />
        )}
      </div>

      <div style={{ textAlign: 'center' }}>
        <Button
          onClick={handleBack}
          style={{
            backgroundColor: colors.danger,
            marginRight: 8,
            color: 'white',
          }}
        >
          {` Voltar `}
        </Button>
        <Button
          data-testid="save-new-operator"
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Salvar
          {loading && <CircularProgress size={24} className={classesGeneric.buttonProgress} />}
        </Button>
      </div>
    </div>
  );
}
