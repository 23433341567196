import React, { createContext, useContext, useState } from 'react';
import { AlertMessage } from '../interfaces/AlertMessage';

interface LoaderContextData {
  loading: boolean;
  changeLoading(value: boolean): void;
  alertMessage: AlertMessage | null;
  triggerAlertMessage(data: AlertMessage | null): void;
}

const LoaderContext = createContext<LoaderContextData>({} as LoaderContextData);

const LoaderProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);

  const changeLoading = (value: boolean): void => {
    setLoading(value);
  };

  const triggerAlertMessage = (data: AlertMessage | null): void => {
    setAlertMessage(data);
  };

  const valueData = {
    loading,
    changeLoading,
    alertMessage,
    triggerAlertMessage,
  };
  return <LoaderContext.Provider value={valueData}>{children}</LoaderContext.Provider>;
};

function useLoader() {
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error('useLoader must be used within an LoaderProvider');
  }

  return context;
}

export { LoaderContext, LoaderProvider, useLoader };
