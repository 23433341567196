import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Project } from '../../../../interfaces/Project';
import { colors } from '../../../../variables/Colors';

interface ModalDeleteProjectProps {
  open: boolean;
  handleClose: () => void;
  onDelete: (id: string) => void;
  info: Project;
}

export default function ModalDeleteProject(props: ModalDeleteProjectProps): JSX.Element {
  const { info, handleClose, onDelete, open } = props;

  const onSave = () => {
    onDelete(info.id);
  };

  return (
    <Dialog open={open} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle
        style={{ textAlign: 'center' }}
        id="form-dialog-title"
        data-testid="form-dialog-title"
      >
        <b>Deletar projeto {info?.name}</b>
      </DialogTitle>

      <DialogContent>
        <Grid style={{ textAlign: 'center' }}>
          <p>Deseja realmente <b>EXCLUIR</b> o projeto {info.name}?</p>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              onClick={handleClose}
              color="primary"
            >
              Cancelar
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              style={{
                backgroundColor: colors.danger,
                color: 'white',
              }}
              onClick={() => onSave()}
              color="default"
              id="modal-save"
            >
              Excluir 
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
