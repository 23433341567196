import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { colors } from '../../../../../variables/Colors';
import DefineTasks from '../DefineTasks/DefineTasks';
import ProjectUserOperatorService from '../../../../../services/ProjectUserOperatorService';
import { DataTasks } from '../DefineTasks/interfaces';
import ValidationService from '../../../../../services/ValidationService';
import { useLoader } from '../../../../../contexts/LoaderContext';
import { classGeneric } from '../../../../../variables/Styles';
import { useOperator } from '../../OperatorContext';
import NoDataFound from '../../../../../components/NoDataFound';
import { ProjectUserOperator } from '../../../../../interfaces/Operator';
import { EditProjectUserOperator } from '../../../../../interfaces/Project';

interface EditTasksOperatorProps {
  id: number | string;
  data: DataTasks;
  handleClose: () => void;
}

export default function EditTasksOperator(props: EditTasksOperatorProps): JSX.Element {
  const { handleClose, data, id } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [dataTasks, setDataTasks] = useState(data);
  const { triggerAlertMessage } = useLoader();
  const classesGeneric = classGeneric();
  const { currentProjectOperationUser } = useOperator();

  const getDataTasks = (dataOperations: DataTasks) => {
    return setDataTasks(dataOperations);
  };

  const onSubmitEditTasks = () => {
    const dataProjectsOperations = ProjectUserOperatorService.prepareDataDefinedTasks(
      dataTasks,
      id,
    );

    const currentDataProjectOperations = ProjectUserOperatorService.prepareCurrentProjectOperation(
      currentProjectOperationUser
    );

    currentDataProjectOperations.forEach(currentItem => {
      if (!dataProjectsOperations.find(element => element.project === currentItem.project)) {
        const finalData = { ...currentItem, useroperator: id }
        updateProjectOperationsUser(currentItem.id, finalData);
        setLoading(true);
      }
    })

    let hasUpdated = false;
    dataProjectsOperations.forEach(item => {
      if (currentProjectOperationUser.find(element => element.project_id === item.project)) {
        currentProjectOperationUser.find(element => {
          if (element.project_id === item.project) {
            updateProjectOperationsUser(element.id, item);
            setLoading(true);
            hasUpdated = true;
          }
        });
      } else {
        hasUpdated = true;
        saveNewProjectOperationsUser(item);
      }
    });
    hasUpdated && successUpdated();
  };

  const saveNewProjectOperationsUser = (data: ProjectUserOperator) => {
    ProjectUserOperatorService.save(data).subscribe(
      response => {
        return response;
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        return error;
      },
    );
  };

  const updateProjectOperationsUser = (id: string, data: EditProjectUserOperator) => {
    const { project, ...newData } = data;

    ProjectUserOperatorService.update(id, newData).subscribe(
      response => {
        return response;
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        return error;
      },
    );
  };

  const successUpdated = () => {
    handleClose();
    setLoading(false);
    triggerAlertMessage({
      type: 'success',
      msg: 'Atividades do operador alteradas com sucesso',
    });
    setLoading(false);
  };

  return (
    <div>
      {dataTasks.systems.length > 0 ?
        <DefineTasks dataTasks={dataTasks} setDataCallback={getDataTasks} />
        : <NoDataFound text="Nenhum sistema encontrado" variant="outlined" />
      }

      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <Button
          onClick={handleClose}
          style={{
            backgroundColor: colors.danger,
            marginRight: 8,
            color: 'white',
          }}
        >
          {` Cancelar `}
        </Button>
        <Button
          onClick={onSubmitEditTasks}
          disabled={loading}
          variant="contained"
          color="primary"
          id="task-save"
        >
          Salvar
          {loading && <CircularProgress size={24} className={classesGeneric.buttonProgress} />}
        </Button>
      </div>
    </div>
  );
}
