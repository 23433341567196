import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { System } from '../../../../interfaces/System';
import Input from '../../../../components/Input';
import { CircularProgress, Grid } from '@material-ui/core';
import styles from '../System.module.scss';
import { colors } from '../../../../variables/Colors';
import { MessageValidation } from '../../../../util/MessageValidation';
import ValidationService from '../../../../services/ValidationService';
import SystemService from '../../../../services/SystemService';
import { useLoader } from '../../../../contexts/LoaderContext';
import { classGeneric } from '../../../../variables/Styles';

interface ModalSystemProps {
  open: boolean;
  handleClose: () => void;
  listSystems: () => void;
  info?: System;
}

export default function ModalSystem(props: ModalSystemProps): JSX.Element {
  const { info, handleClose, listSystems, open } = props;
  const [id, setId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { triggerAlertMessage } = useLoader();
  const classes = classGeneric();

  const initialData = {
    name: info?.name,
    callback_queue: info?.callback_queue,
  };

  const formRef = useRef<FormHandles>(null);

  const [isNewSystem, setIsNewSystem] = useState<boolean>(true);

  async function setInitialData() {
    return initialData;
  }

  useEffect(() => {
    if (open) {
      if (info?.id) {
        setId(info?.id);
        setIsNewSystem(false);
        setInitialData().then(initialData => {
          formRef.current?.setData(initialData);
        });
      } else {
        setIsNewSystem(true);
      }
    }
  }, [open]);

  const submitForm = (data: System) => {
    validateFields().then(response => {
      if (response) {
        setLoading(true);
        isNewSystem ? onSave(data) : onUpdate(data);
      }
    });
  };

  const onSave = (data: System) => {
    SystemService.save(data).subscribe(
      response => {
        finalizeProcessForm();
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        setLoading(false);
      },
    );
  };

  const onUpdate = (data: System) => {
    SystemService.update(id, data).subscribe(
      response => {
        finalizeProcessForm();
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        setLoading(false);
      },
    );
  };

  const finalizeProcessForm = () => {
    handleClose();
    listSystems();
    setLoading(false);
    triggerAlertMessage({
      title: 'Sistema',
      msg: `${isNewSystem ? 'Cadastrado' : 'Alterado'} com sucesso !`,
      type: 'success',
    });
  };

  const validateFields = () => {
    const rules = {
      name: Yup.string().required(MessageValidation.required),
      callback_queue: Yup.string().required(MessageValidation.required),
    };
    return ValidationService.validate(formRef?.current, rules);
  };

  return (
    <div>
      <Dialog
        data-testid="modal-system"
        open={open}
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ textAlign: 'center' }} id="form-dialog-title">
          <b>{isNewSystem ? 'Criar sistema' : `Editar sistema ${info?.name}`} </b>
        </DialogTitle>

        <Form ref={formRef} initialData={initialData} onSubmit={submitForm} data-testid="form-system">
          <DialogContent style={{ padding: 50 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={styles.formSpace}>
                <Input data-testid="form-name" label="Nome" name="name" fullWidth />
              </Grid>

              <Grid item xs={12} className={styles.formSpace}>
                <Input data-testid="form-callback" label="Callback" name="callback_queue" fullWidth />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                {!isNewSystem && (
                  <Button
                    size="large"
                    style={{
                      backgroundColor: colors.danger,
                      color: 'white',
                    }}
                    fullWidth
                    variant="contained"
                    onClick={handleClose}
                    color="default"
                  >
                    Cancelar
                  </Button>
                )}
              </Grid>

              <Grid item xs={isNewSystem ? 12 : 6}>
                <Button
                  data-testid="btn-submit"
                  disabled={loading}
                  size="large"
                  fullWidth
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  Salvar
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Form>
      </Dialog>
    </div>
  );
}
