import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ItemSystem from './components/ItemSystem';
import ModalSystem from './components/ModalSystem';
import { System as SystemInterface } from '../../../interfaces/System';
import { Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import styles from './System.module.scss';
import { useLoader } from '../../../contexts/LoaderContext';
import SystemService from '../../../services/SystemService';
import NoDataFound from '../../../components/NoDataFound';

export default function System() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<SystemInterface>({} as SystemInterface);
  const [systems, setDataSystem] = useState<Array<SystemInterface>>([] as SystemInterface[]);

  const { changeLoading } = useLoader();

  useEffect(() => {
    listSystems();
  }, []);

  const listSystems = () => {
    changeLoading(true);
    SystemService.list().subscribe(
      (response: SystemInterface[]) => {
        setDataSystem(response);
        changeLoading(false);
      },
      error => {
        changeLoading(false);
      },
    );
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChangeInfo = (info = {} as SystemInterface) => {
    setInfoModal(info);
  };

  const handleOpenNewSystem = () => {
    handleChangeInfo();
    handleOpen();
  };

  return (
    <div id="system" style={{ padding: 30 }}>
      <Typography variant="h5">
        Sistemas
        <Button
          data-testid="btn-create"
          className={styles.buttonCreate}
          onClick={handleOpenNewSystem}
          size="large"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Criar
        </Button>
      </Typography>

      <TableContainer component={Paper}>
        <Table data-testid="table-system" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <b>Sistema</b>
              </TableCell>
              <TableCell align="right">
                <b>Callback</b>
              </TableCell>
              <TableCell align="right">
                <b>Ações</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {systems.map(item => (
              <ItemSystem
                key={item.id}
                item={item}
                handleOpen={handleOpen}
                handleChangeInfo={handleChangeInfo}
                listSystems={listSystems}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {systems.length == 0 && <NoDataFound />}
      <ModalSystem
        data-testid="modal-sys"
        open={showModal}
        handleClose={handleClose}
        info={infoModal}
        listSystems={listSystems}
      />
    </div>
  );
}
