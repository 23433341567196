import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Operator } from '../../../../../interfaces/Operator';
import { colors } from '../../../../../variables/Colors';
import { useOperator } from '../../OperatorContext';
import OperatorService from '../../../../../services/OperatorService';
import { classGeneric } from '../../../../../variables/Styles';
import ValidationService from '../../../../../services/ValidationService';
import { useLoader } from '../../../../../contexts/LoaderContext';

interface ItemsToDelete {
  id: string;
  index: number;
  name: string;
}

interface DeleteOperatorsProps {
  open: boolean;
  handleClose: () => void;
  itemsToDelete: Array<ItemsToDelete>;
}

export default function DeleteOperators(props: DeleteOperatorsProps) {
  const { open, handleClose, itemsToDelete } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const { dataOperators, changeDataOperators } = useOperator();
  const classes = classGeneric();
  const { triggerAlertMessage } = useLoader();

  const deleteOperators = () => {
    processDelete();
  };

  const processDelete = () => {
    setLoading(true);
    itemsToDelete.forEach((item, index) => {
      OperatorService.delete(item.id).subscribe(
        response => {
          return true;
        },
        error => {
          ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        },
      );
      if (index == itemsToDelete.length - 1) {
        deleteDataTableOperators();
      }
    });
  };

  const deleteDataTableOperators = (): void => {
    const newDataTable = Array<Operator>();
    dataOperators.map((operator, index) => {
      if (!itemsToDelete.find(operator => operator.index == index)) {
        newDataTable.push(operator);
      }
    });
    triggerAlertMessage({
      type: 'success',
      msg: 'Operadores excluidos com sucesso !',
    });
    changeDataOperators(newDataTable);
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deletar operadores</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <b>Você está prestes a deletar os usuários abaixo, deseja continuar ?</b>
          <ul>
            {itemsToDelete.map(item => (
              <li key={item.id}>{item.name}</li>
            ))}
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            backgroundColor: colors.danger,
            color: 'white',
          }}
          variant="contained"
          onClick={handleClose}
          color="default"
        >
          Cancelar
        </Button>
        <Button disabled={loading} onClick={deleteOperators} variant="contained" type="submit" color="primary">
          Continuar
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
