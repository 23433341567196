import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Button, Grid, IconButton, InputAdornment, Popover, Tooltip, Typography } from '@material-ui/core';
import { Form } from '@unform/web';
import Input from '../../../../../../components/Input';
import styles from '../../../Operators.module.scss';
import { colors } from '../../../../../../variables/Colors';
import ValidationService from '../../../../../../services/ValidationService';
import { MessageValidation } from '../../../../../../util/MessageValidation';
import { FormHandles } from '@unform/core';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import { generatePassword } from '../../../../../../util/Helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckIcon from '@material-ui/icons/Check';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Operator } from '../../../../../../interfaces/Operator';

interface StepDataOperatorProps {
  nextStep: () => void;
  dataOperator: Operator | DataInfo | undefined;
  changeDataOperator: (data: Operator | DataInfo) => void;
}

export interface DataInfo {
  name: string;
  email: string;
  password: string;
}

export default function StepDataOperator(props: StepDataOperatorProps): JSX.Element {
  const formRef = useRef<FormHandles>(null);

  const { nextStep, dataOperator, changeDataOperator } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [dataCopy, setDataCopy] = useState({
    text: '',
    copied: false,
  });

  useEffect(() => {
    if (dataOperator) {
      formRef.current?.setData(dataOperator);
      setDataCopy({ text: dataOperator.password || '', copied: false });
    } else {
      const password = generatePassword();
      formRef.current?.setFieldValue('password', password);
      setDataCopy({ text: password, copied: false });
    }
  }, []);

  const submitNextStep = (data: Operator | DataInfo) => {
    validateStep().then(response => {
      if (response) {
        changeDataOperator(data);
        nextStep();
      }
    });
  };

  const validateStep = () => {
    const rules = {
      first_name: Yup.string().required(MessageValidation.required),
      username: Yup.string().required(MessageValidation.required),
      email: Yup.string().required(MessageValidation.required).email(MessageValidation.email),
      password: Yup.string().required(MessageValidation.required),
    };
    return ValidationService.validate(formRef?.current, rules);
  };

  const copyPassword = () => {
    setDataCopy({
      text: dataCopy.text,
      copied: true,
    });

    setTimeout(() => {
      setDataCopy({
        text: dataCopy.text,
        copied: false,
      });
    }, 3000);
  };

  return (
    <Form ref={formRef} onSubmit={submitNextStep}>
      <Grid container xs={12}>
        <Grid item xs={12} className={styles.formSpace}>
          <Input fullWidth variant="outlined" name="first_name" label="Nome" />
        </Grid>

        <Grid item xs={12} className={styles.formSpace}>
          <Input fullWidth variant="outlined" name="email" label="E-mail" />
        </Grid>

        <Grid item xs={12} className={styles.formSpace}>
          <Input fullWidth variant="outlined" name="username" label="Username" />
        </Grid>

        <Grid item xs={12} className={styles.formSpace}>
          <Input
            id="password"
            fullWidth
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            name="password"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Visualizar" aria-label="show">
                    <IconButton
                      style={{ right: -20 }}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copiar" aria-label="copy">
                    <CopyToClipboard text={dataCopy.text} onCopy={copyPassword}>
                      {dataCopy.copied ? (
                        <>
                          <IconButton
                            aria-describedby="success-copied"
                            style={{ right: -10 }}
                            aria-label="toggle copy password"
                            onClick={() => copyPassword()}
                          >
                            <CheckIcon style={{ color: colors.success }} />
                          </IconButton>
                          <Popover
                            id="success-copied"
                            open={dataCopy.copied}
                            onClose={() =>
                              setDataCopy({
                                text: dataCopy.text,
                                copied: false,
                              })
                            }
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Typography style={{ padding: 16 }}>Copiado para area de transferência</Typography>
                          </Popover>
                        </>
                      ) : (
                        <IconButton
                          style={{ right: -10 }}
                          aria-label="toggle copy password"
                          onClick={() => copyPassword()}
                        >
                          <FileCopySharpIcon />
                        </IconButton>
                      )}
                    </CopyToClipboard>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            label="Senha"
          />
        </Grid>
      </Grid>

      <div style={{ textAlign: 'center' }}>
        <Button
          style={{
            backgroundColor: colors.grey,
            marginRight: 8,
            color: 'white',
          }}
          disabled
        >
          {' '}
          Voltar{' '}
        </Button>
        <Button type="submit" variant="contained" id="step-next" color="primary">
          Próximo
        </Button>
      </div>
    </Form>
  );
}
