import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from './Colors';

const classGeneric = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: colors.primary,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

export { classGeneric };
