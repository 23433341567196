import { Observable } from 'rxjs';
import { UserResponse } from '../interfaces/User';
import { LoginData } from '../pages/Login/Login';
import { getUrl } from '../util/Helpers';
import { HttpClient } from './api/HttpClient';

class AuthService<T> {
  protected baseUrl(): string {
    return 'api-token-auth';
  }

  login(data: LoginData): Observable<UserResponse> {
    return HttpClient.post(getUrl(this.baseUrl()), data, {}, true);
  }
}

export default new AuthService();
