import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { System } from '../../../../interfaces/System';
import ModalProject from '../../Projects/components/ModalProject';
import { Project, OperationEnum } from '../../../../interfaces/Project';
import moment from 'moment';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

interface ItemSystemProps {
  item: System;
  handleOpen: () => void;
  handleChangeInfo: (item: System) => void;
  listSystems: () => void;
}

function ItemSystem(props: ItemSystemProps): JSX.Element {
  const { item, handleOpen, handleChangeInfo, listSystems } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const [infoProject, setInfoProject] = useState<Project>({} as Project);
  const [showModalProject, setShowModalProject] = useState<boolean>(false);

  moment.locale('pt-br');

  const handleCloseModalProject = () => {
    setShowModalProject(false);
  };

  const handleModalProject = (project: Project = {} as Project) => {
    setInfoProject(project);
    setShowModalProject(true);
  };

  const openEditModalSystem = () => {
    handleOpen();
    handleChangeInfo(item);
  };

  const getOperations = (operations: number[]) => {
    if (!operations) return [];

    return operations
      .map((item: number) => {
        return Object.values(OperationEnum)[item - 1];
      })
      .join(',');
  };

  return (
    <React.Fragment>
      <TableRow data-testid={item.name} className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {item.name}
        </TableCell>
        <TableCell align="right">{item.callback_queue}</TableCell>
        <TableCell align="right">
          <IconButton onClick={openEditModalSystem} aria-label="edit">
            <EditIcon data-testid="btn-edit" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Projetos
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handleModalProject();
                  }}
                  style={{
                    float: 'right',
                  }}
                >
                  Criar Projeto
                </Button>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell align="right">Atividades</TableCell>
                    <TableCell align="right">Criado em</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.projects?.map((project: Project) => (
                    <TableRow key={project.name}>
                      <TableCell>{project.name}</TableCell>
                      <TableCell align="right">
                        {getOperations(project?.operations as number[])}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        {moment(project.created_at).format('DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleModalProject(project)} aria-label="edit">
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <ModalProject
        open={showModalProject}
        handleClose={handleCloseModalProject}
        info={infoProject}
        listProjects={listSystems}
      />
    </React.Fragment>
  );
}

export default ItemSystem;
