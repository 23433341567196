import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { StatusRouteType } from '../../interfaces/App';
import { StatusPage } from '../../pages';

interface RouteStatusProps {
    status: StatusRouteType;
    exact?: boolean;
    path?: string;
}

const RouteStatus = (props: RouteStatusProps) => {
    const { status, ...rest } = props;

    return (
        <Route
            {...rest}
            render={matchProps => (
                <StatusPage status={status} {...matchProps} />
            )}
        />
    )
};

export default RouteStatus;
