import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepDataOperator, { DataInfo } from './Steps/StepDataOperator';
import StepTasks from './Steps/StepTasks';
import { Operator } from '../../../../../interfaces/Operator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    content: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

function getSteps() {
  return ['Dados de acesso', 'Definir tarefas'];
}

function getStepContent(
  stepIndex: number,
  handleNext: () => void,
  handleBack: () => void,
  dataOperator: Operator | DataInfo | undefined,
  changeDataOperator: (data: Operator | DataInfo) => void,
  handleClose: () => void,
) {
  switch (stepIndex) {
    case 0:
      return (
        <StepDataOperator nextStep={handleNext} dataOperator={dataOperator} changeDataOperator={changeDataOperator} />
      );
    case 1:
      return (
        <StepTasks
          dataOperator={(dataOperator as Operator)}
          handleBack={handleBack}
          handleClose={handleClose}
        />
      );
    default:
      return 'Passo não encontrado';
  }
}

export default function StepsCreate({ handleClose }: { handleClose: () => void }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [dataOperator, setDataOperator] = useState<Operator | DataInfo>();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const changeDataOperator = (data: Operator | DataInfo) => {
    setDataOperator(data);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <div className={classes.content}>
            {getStepContent(activeStep, handleNext, handleBack, dataOperator, changeDataOperator, handleClose)}
          </div>
        </div>
      </div>
    </div>
  );
}
