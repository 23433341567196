import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumOperations } from '../consts/Operations';
import { Project } from '../interfaces/Project';
import { System, SystemCallBack } from '../interfaces/System';
import { DataTasks } from '../pages/admin/Operators/components/DefineTasks/interfaces';
import { CrudService } from './api/CrudService';
import { HttpClient } from './api/HttpClient';

class SystemService extends CrudService<System> {
  protected baseUrl(): string {
    return 'systems/';
  }

  loadSystemsTasks(): Observable<DataTasks> {
    return this.list().pipe(
      map(response => {
        return this.mountDataSystemTask(response);
      }),
    );
  }

  private mountDataSystemTask(response: System[]): DataTasks {
    const systems = Array();

    response.forEach((system: System) => {
      const dataSystem = {
        id: system.id,
        name: system.name,
        allowed: false,
        projects: Array(),
      };

      system.projects.forEach((project: Project) => {
        const dataProject = {
          id: project.id,
          name: project.name,
          allowed: false,
          operations: Array(),
        };

        project.operations?.forEach(item => {
          dataProject.operations.push({
            id: item,
            name: `${EnumOperations[(item as number) - 1]}`,
            allowed: false,
          });
        });
        dataSystem.projects.push(dataProject);
      });
      systems.push(dataSystem);
    });

    return { systems };
  }

  callback_system_operation(id_system: string, data: SystemCallBack): Observable<System> {
    return HttpClient.patch(`${this.baseUrl()}${id_system}/callback`, data);
  }

  callback_error(id_system: string, data: SystemCallBack): Observable<System> {
    return HttpClient.patch(`${this.baseUrl()}${id_system}/callback_error`, data);
  }
}

export default new SystemService();
