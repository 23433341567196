import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { OperationEnum } from '../../../../interfaces/Project';
import { colors } from '../../../../variables/Colors';

interface ModalDeleteOperationProps {
  open: boolean;
  handleClose: () => void;
  onDelete: (operation: number) => void;
  operation: number;
  project: string;
}

export default function ModalDeleteOperation(props: ModalDeleteOperationProps): JSX.Element {
  const { operation, project, handleClose, onDelete, open } = props;

  const onSave = () => {
    onDelete(operation);
  };

  return (
    <Dialog open={open} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle
        style={{ textAlign: 'center' }}
        id="form-dialog-title"
        data-testid="form-dialog-title"
      >
        <b>Deletar operação {Object.values(OperationEnum)[operation - 1]}</b>
      </DialogTitle>

      <DialogContent>
        <Grid style={{ textAlign: 'center' }}>
          <p>
            Deseja realmente <b>EXCLUIR</b> a operação
            <b> {Object.values(OperationEnum)[operation - 1]} </b>
            do projeto {project}?
          </p>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              onClick={handleClose}
              color="primary"
            >
              Cancelar
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              style={{
                backgroundColor: colors.danger,
                color: 'white',
              }}
              onClick={() => onSave()}
              color="default"
              id="modal-save"
            >
              Excluir 
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
