import { System } from './System';

export enum OperationEnum {
  CLASSIFICATION = 'Classificação',
  EXTRACTION = 'Extração',
  VALIDATION = 'Validação',
  EXTRACTIONVALIDATION = 'Extração e Validação',
}

export enum SchemaTypeEnum {
  STRING = 'Texto',
  NUMBER = 'Número',
  SELECT = 'Seleção',
  BOOLEAN = 'Booliano',
}

export interface Schema {
  type: SchemaTypeEnum;
  label: string;
  name: string;
  options?: string[] | number[];
}

export interface EditProjectUserOperator {
  id: string;
  project: string;
  operations: number[];
}

export interface Project {
  id: string;
  deleted: boolean;
  name: string;
  notification_time?: number;
  notification_url?: string;
  use_default: boolean;
  hours?: number;
  minutes?: number;
  seconds?: number;
  display_reject: boolean;
  display_operations?: string;
  operations: Array<number | string>;
  system_data?: System;
  system: string;
  schemas: Schema[];
  updated_at: string;
  created_at: string;
}
