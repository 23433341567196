import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import FormBuilder from '../../components/FormBuilder/FormBuilder';
import ValidationService from '../../services/ValidationService';
import SchemaService from '../../services/SchemaService';
import Initial from './components/Initial';
import { useLoader } from '../../contexts/LoaderContext';
import { OperationEnum, Project, Schema } from '../../interfaces/Project';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Documents from './components/Documents';
import { useUserOperator } from '../../contexts/UserOperatorContext';
import { SchemaModel } from '../../interfaces/Schema';
import NoDataFound from '../../components/NoDataFound';
import SystemService from '../../services/SystemService';
import TaskService from '../../services/TaskService';
import { Task } from '../../interfaces/Task';
import FormBuilderEV from '../../components/FormBuilderExtractionValidation/FormBuilderEV';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '10px 0',
  },
  header: {
    textAlign: 'left',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  card: {
    padding: '20px 20px 10px',
  },
  documents: {
    borderRight: '1px solid #666',
  },
  builder: {
    marginLeft: 20,
  },
});

const baseURL = process.env.REACT_APP_WS_URL;

export default function OperatorUser(): JSX.Element {
  const classes = useStyles();

  const {
    project,
    changeDataProject,
    schema,
    changeDataSchema,
    operation,
    changeOperation,
    changeDataForm,
    loadTask,
    task,
    changeTask,
    documents,
  } = useUserOperator();

  const { triggerAlertMessage, changeLoading } = useLoader();
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [loadItems, setLoadItems] = useState(() => () => {/**/});
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
  const [projectSocket, setProjectSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    const socket = webSocket ? webSocket : new WebSocket(`${baseURL}/all/`);
    socket.onmessage = (e) => {
      if (!project) loadItems();
    };

    socket.onclose = () => {
      function reconnect(conn: boolean) {
        const interval = setInterval(() => {
          if (!conn) return;

          let connected = false;
          const newSocket = new WebSocket(`${baseURL}/all/`);
          newSocket.onmessage = (e) => {
            if (!project) loadItems();
          }
          newSocket.onopen = () => {
            setWebSocket(newSocket);
            connected = true;
            clearInterval(interval);
          }

          newSocket.onclose = () => {
            reconnect(connected);
          }
        }, 5000);
      }

      reconnect(true);
    }

    const pSocket = !projectSocket && project ? new WebSocket(`${baseURL}/${project?.id}/`) : projectSocket;

    if (project && !task?.id && pSocket) {
      let requesting = false;
      pSocket.onmessage = () => {
        if (project?.id && operation) {
          if (!requesting) {
            requesting = true;
            loadTask()
          }
        }
      }

      socket.onclose = () => {
        function reconnect(conn: boolean) {
          const interval = setInterval(() => {
            if (!conn) return;
  
            let connected = false;
            const newSocket = new WebSocket(`${baseURL}/${project?.id}/`);
            let requesting = false;
            newSocket.onmessage = () => {
              if (project?.id && operation) {
                if (!requesting) {
                  requesting = true;
                  loadTask()
                }
              }
            }
            newSocket.onopen = () => {
              setProjectSocket(newSocket);
              connected = true;
              clearInterval(interval);
            }
  
            newSocket.onclose = () => {
              reconnect(connected);
            }
          }, 5000);
        }
  
        reconnect(true);
      }
    } else if (pSocket) {
      pSocket.onmessage = () => {
        // pass
      };
    }

    if (!project) {
      setProjectSocket(null);
    } else {
      setProjectSocket(pSocket);   
    }

    setWebSocket(socket);
  }, [loadItems, project, task]);
  
  useEffect(() => {
    if (project?.id && !task?.id) loadTask();
  }, [operation]);

  useEffect(() => {
    if (project?.id && operation) loadSchema(project?.id, operation);
  }, [documents]);

  const handleProject = (project: Project, operation: number) => {
    changeDataProject(project);
    changeOperation(operation);
  };

  const handleBack = () => {
    changeLoading(true);
    if (task?.id) TaskService.workOutAllTasks();
    setTimeout(() => {
      changeDataProject(null);
      changeDataSchema(null);
      changeOperation(null);
      changeTask({} as Task);
      changeDataForm(null);
      changeLoading(false);
    }, 300);
  };

  const loadSchema = (project: string, operation: number) => {
    setStartDate(new Date());
    setLoading(true);
    SchemaService.list({ project, operation }).subscribe(
      (response: SchemaModel[]) => {
        const activity = response.find((item: SchemaModel) => item.operation === operation);
        if (activity) changeDataSchema(activity);
        setLoading(false);
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        setLoading(false);
      },
    );
  };

  const handleNext = (data: unknown, observation: string, success_status: number, error_fields: string[]) => {
    processOperation(data, observation, success_status, error_fields);
    return data;
  };

  const processOperation = (data: unknown, observation: string, success_status: number, error_fields: string[]) => {
    if (project) {
      changeLoading(true);
      setLoading(true);
      const system_id = project?.system;
      const finalData = { 
        task_id: task.id, 
        data, 
        start: startDate, 
        end: new Date(),
        observation: observation || null,
        error_fields: error_fields.length ? error_fields : null,
        success_status,
      };
      SystemService.callback_system_operation(system_id, finalData).subscribe(
        response => {
          changeLoading(false);
          changeDataForm({});
          setLoading(false);
          triggerAlertMessage({
            type: 'success',
            msg: 'Operação realizada com sucesso',
          });
          loadTask();
        },
        error => {
          changeLoading(false);
          setLoading(false);
          ValidationService.validateBackEndAlert(error, triggerAlertMessage);
          return error;
        },
      );
    }
  };
  
  const rejectTask = (data: unknown) => {
    if (project) {
      changeLoading(true);
      setLoading(true);
      const system_id = project?.system;
      const finalData = { 
        task_id: task.id, 
        data, 
        start: startDate, 
        end: new Date(),
      };
      SystemService.callback_error(system_id, finalData).subscribe(
        response => {
          changeLoading(false);
          changeDataForm({});
          setLoading(false);
          triggerAlertMessage({
            type: 'success',
            msg: 'Tarefa rejeitada com sucesso',
          });
          loadTask();
        },
        error => {
          changeLoading(false);
          setLoading(false);
          ValidationService.validateBackEndAlert(error, triggerAlertMessage);
          return error;
        },
      );
    }
  };

  const selectFormField = () => {
    if (operation === 4) {
      return <FormBuilderEV
        data={task.result}
        metadata={task.metadata}
        actionForm={handleNext}
        rejectTask={rejectTask}
        displayReject={project?.display_reject || false}
      />;
    }
    return <FormBuilder
      data={schema?.data}
      metadata={task.metadata}
      actionForm={handleNext}
      rejectTask={rejectTask}
      displayReject={project?.display_reject || false}
    />;
  };

  return (
    <>
      {!project ? (
        <Initial selectProject={handleProject} setLoadItems={setLoadItems} />
      ) : (
        <div id="current-project" className={classes.root}>
          <div className={classes.header}>
            <Button
              data-testid="back-to-projects"
              style={{ float: 'right' }}
              variant="contained"
              color="primary"
              onClick={handleBack}
              startIcon={<KeyboardBackspaceIcon />}
            >
              Voltar
            </Button>
            <h1>{project.name}</h1>
          </div>

          {task?.id ? (
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item className={classes.documents} xs={12} md={8}>
                    <Documents
                      loading={loading}
                      setLoading={setLoading}
                      actionForm={handleNext}
                      rejectTask={rejectTask}
                      displayReject={project.display_reject}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div>{operation && <h2>{Object.values(OperationEnum)[operation - 1]}</h2>}</div>
                    <div className={classes.builder}>
                      {loading ? <CircularProgress size={24} /> : selectFormField()}
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : (
            <NoDataFound text="Não contém nenhuma tarefa para esse projeto e operação!" />
          )}
        </div>
      )}
    </>
  );
}
