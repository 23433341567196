import React, { createContext, useContext, useEffect, useState } from 'react';
import { OperationsPermissionProject, Operator } from '../../../interfaces/Operator';
import { DataTasks } from './components/DefineTasks/interfaces';

interface OperatorContextData {
  dataOperators: Array<Operator>;
  changeDataOperators(data: Array<Operator>): void;
  initialDataTask: DataTasks | null;
  changeInitialDataTask(data: DataTasks): void;
  currentProjectOperationUser: Array<OperationsPermissionProject>;
  changeCurrentProjectOperationUser(data: Array<OperationsPermissionProject>): void;
}

const OperatorContext = createContext<OperatorContextData>({} as OperatorContextData);

const OperatorProvider: React.FC = ({ children }) => {
  const [dataOperators, setDataOperators] = useState<Operator[]>([]);
  const [initialDataTask, setInitialDataTask] = useState<DataTasks | null>(null);
  const [currentProjectOperationUser, setCurrentProjectsOperationsUser] =
    useState<Array<OperationsPermissionProject>>([]);

  const changeDataOperators = (data: Array<Operator>) => {
    setDataOperators(data);
  };

  const changeInitialDataTask = (data: DataTasks) => {
    setInitialDataTask(data);
  }

  const changeCurrentProjectOperationUser = (data: Array<OperationsPermissionProject>) => {
    setCurrentProjectsOperationsUser(data);
  }

  const values: OperatorContextData = {
    dataOperators,
    changeDataOperators,
    initialDataTask,
    changeInitialDataTask,
    currentProjectOperationUser,
    changeCurrentProjectOperationUser
  };

  return <OperatorContext.Provider value={values}>{children}</OperatorContext.Provider>;
};

function useOperator() {
  const context = useContext(OperatorContext);

  if (!context) {
    throw new Error('useOperator must be used within an OperatorProvider');
  }

  return context;
}

export { OperatorContext, OperatorProvider, useOperator };
