import React, { useState } from 'react';
import { Card, Fab, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { Schema } from '../../../../interfaces/Project';
import Input from '../../../../components/Input';
import { SchemaOption } from '../../../../interfaces/Schema';

interface SelectOptionProps {
  optionsList: SchemaOption[];
  handleOptionList: (data: SchemaOption[]) => void;
}

export default function SelectOptions(props: SelectOptionProps): JSX.Element {
  const { optionsList, handleOptionList } = props;

  const [label, setLabel] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const onSubmit = () => {
    if (label && value) {
      const data = { label, value };
      handleOptionList([...optionsList, data]);
      setLabel('');
      setValue('');
    }
  };

  const deleteOption = (label: string) => {
    const data = optionsList.filter((item: SchemaOption) => item.label !== label);
    handleOptionList(data);
  };

  const handleLabel = (text: string) => {
    setLabel(text);
  };

  const handleValue = (text: string) => {
    setValue(text);
  };

  return (
    <Grid style={{ padding: '0 20px' }} xs={12}>
      <Grid item xs={12}>
        <h3 style={{ marginBottom: 0 }}>Adicione as opções:</h3>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Grid style={{ padding: '5px 0' }} item xs={12}>
            <Input
              id="optionLabel"
              size="small"
              label="Nome Opção"
              name="optionLabel"
              fullWidth
              value={label}
              onChange={handleLabel}
            />
          </Grid>
          <Grid style={{ padding: '5px 0' }} item xs={12}>
            <Input
              id="optionValue"
              size="small"
              label="Valor Backend"
              name="optionValue"
              fullWidth
              value={value}
              onChange={handleValue}
            />
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            style={{ float: 'right', marginBottom: 10 }}
            onClick={onSubmit}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>

      {optionsList.length > 0 && (
        <Card>
          <List style={{ overflow: 'auto', maxHeight: 238 }}>
            {optionsList.map((schema: SchemaOption) => {
              return (
                <ListItem key={schema.label}>
                  <ListItemText primary={schema.label} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => deleteOption(schema.label)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Card>
      )}
    </Grid>
  );
}
