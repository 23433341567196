import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button, Badge, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { OperationEnum, Project } from '../../../../interfaces/Project';
import ModalSchema from './ModalSchema';
import { SchemaInfo } from '../../../../interfaces/Schema';
import { AmountTasksProjectUser } from '../../../../interfaces/Task';
import ModalDeleteOperation from './ModalDeleteOperation';
import ProjectService from '../../../../services/ProjectService';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  buttonProgress: {
    textAlign: 'center',
    marginTop: 10,
  },
});

interface ItemProjectProps {
  item: Project;
  amountOperation: AmountTasksProjectUser | undefined;
  handleOpen: () => void;
  handleChangeInfo: (item?: Project) => void;
  handleProject: (project: Project, operation: number) => void;
  handleDelete: () => void;
  listProjects: () => void;
}

export default function ItemProject(props: ItemProjectProps): JSX.Element {
  const { item, amountOperation, handleOpen, handleChangeInfo, handleProject, handleDelete, listProjects } = props;
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<SchemaInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteOperation, setDeleteOperation] = useState<number>(0);

  const deleteProject = async () => {
    handleDelete();
    handleChangeInfo(item);
  };

  const changeDeleteOperation = async (operation: number) => {
    setDeleteModal(true);
    setDeleteOperation(operation);
  };

  const handleCloseDeleteOperation = async () => {
    setDeleteModal(false);
  };

  const handleDeleteOperation = async (operation: number) => {
    const operations = item.operations.filter(e => e !== operation).map(e => (e as number));
    ProjectService.deleteOperation(item.id, operations).subscribe(
      () => {
        handleCloseDeleteOperation();
        listProjects();
      },
      error => {
        handleCloseDeleteOperation();
      },
    );
  };

  const openEditModalProject = () => {
    handleOpen();
    handleChangeInfo(item);
  };

  const openListSchemas = () => {
    setOpen(!open);
  };

  const handleCreateNewSchema = (operation: number) => {
    const schemaInfo: SchemaInfo = {
      id: item.id,
      name: item.name,
      operation,
    };
    setInfoModal(schemaInfo);

    handleChangeInfo();
    handleSchemaOpen();
  };
  const handleSchemaOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <TableRow data-testid="item-project" className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => openListSchemas()}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {item.name}
        </TableCell>

        <TableCell align="center">
          {
            item?.operations.map(operation => 
              <Badge
                key={operation}
                badgeContent={amountOperation?.operations[operation]}
                color="error"
                style={{ padding: "0 0 0 12px" }}
              >
                <Button
                  data-testid="btn-operation"
                  variant="contained"
                  size="small"
                  color="primary"
                  key={operation}
                  onClick={() => handleProject(item, operation as number)}
                >
                  {Object.values(OperationEnum)[(operation as number) - 1]}
                </Button>
              </Badge>
           )
          }
        </TableCell>
        <TableCell align="center">{item.system_data?.name}</TableCell>
        <TableCell align="center">{amountOperation?.total_success || "-"}</TableCell>
        <TableCell align="center">{amountOperation?.total}</TableCell>
        <TableCell align="center">
          <IconButton onClick={openEditModalProject} aria-label="edit">
            <EditIcon />
          </IconButton>
          <IconButton onClick={deleteProject} aria-label="edit" style={{ color: 'red' }}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={3}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: '#a0a0a0' }}>Operação</TableCell>
                    <TableCell style={{ color: '#a0a0a0' }} align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.operations && item.operations.map((operation) => (
                    <TableRow key={operation}>
                      <TableCell>{Object.values(OperationEnum)[(operation as number) - 1]}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleCreateNewSchema((operation as number))} aria-label="edit">
                          <EditIcon />
                        </IconButton>
                        <IconButton 
                          onClick={() => changeDeleteOperation(operation as number)} 
                          aria-label="edit" 
                          style={{ color: 'red' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {infoModal && (
        <ModalSchema
          open={showModal}
          handleClose={handleClose}
          info={infoModal}
          handleNewSchema={handleCreateNewSchema}
        />
      )}
      <ModalDeleteOperation 
        open={deleteModal} 
        handleClose={handleCloseDeleteOperation} 
        operation={deleteOperation} 
        project={item.name} 
        onDelete={handleDeleteOperation}
      />
    </React.Fragment>
  );
}
