import React, { useEffect, useRef, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { MuiTableLabels } from '../../../util/MuiTableLabels';
import { Operator, OperatorTable } from '../../../interfaces/Operator';
import { useLoader } from '../../../contexts/LoaderContext';
import DeleteOperators from './components/Delete/DeleteOperators';
import styles from './Operators.module.scss';
import CreateOperator from './components/Create/CreateOperator';
import AddIcon from '@material-ui/icons/Add';
import EditOperator from './components/Edit/EditOperator';
import OperatorService from '../../../services/OperatorService';
import { useOperator } from './OperatorContext';
import SystemService from '../../../services/SystemService';
import { System } from '../../../interfaces/System';
import { OperationEnum, Project } from '../../../interfaces/Project';
import { EnumOperations } from '../../../consts/Operations';

const columns = [
  { name: 'id', label: 'Id', options: { display: false, filter: false } },
  { name: 'first_name', label: 'Nome' },
  { name: 'email', label: 'E-mail' },
  // { name: 'projects', label: 'Projetos' },
  { name: 'actions', label: 'Ações', options: { filter: false } },
];

interface ButtonActionProps {
  id: string;
  handleOpenEdit: (id: string) => void;
}
const ButtonAction = (props: ButtonActionProps): JSX.Element => {
  const { id, handleOpenEdit } = props;

  return (
    <IconButton onClick={() => handleOpenEdit(id)} key={id} aria-label="edit">
      <EditIcon />
    </IconButton>
  );
};

interface ItemSelected {
  index: number;
  dataIndex: number;
}

interface ItemsToDelete {
  id: string;
  index: number;
  name: string;
}

export default function Operators() {
  const [dataTable, setDataTable] = useState<Array<Operator>>([]);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [idOperatorSelected, setIdOperatorSelected] = useState<string>('');
  const [itemsDelete, setItemsDelete] = useState<Array<ItemsToDelete>>([]);

  const { changeLoading } = useLoader();
  const { dataOperators, changeDataOperators } = useOperator();

  useEffect(() => {
    seInDataTable();
  }, [dataOperators]);

  const handleOpenEdit = (id: string) => {
    setOpenEdit(true);
    setIdOperatorSelected(id);
  };

  useEffect(() => {
    changeLoading(true);
    listOperators();
  }, []);

  const listOperators = () => {
    changeLoading(true);
    OperatorService.list().subscribe((operators: Operator[]) => {
      changeDataOperators(operators);
      changeLoading(false);
    });
  };

  const seInDataTable = () => {
    const data = Array();
    dataOperators?.map((operator: Operator) => {
      data.push({
        id: operator.id,
        first_name: operator.first_name,
        email: operator.email,
        // projects: operator.operations.length,
        actions: <ButtonAction handleOpenEdit={handleOpenEdit} id={operator.id} />,
      });
    });
    setDataTable(data);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setIdOperatorSelected('');
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setItemsDelete([]);
  };

  const ActionDeleteOperators = (dataDelete: Array<ItemSelected>) => {
    const operatorsDelete = dataDelete.map(item => {
      const data = dataTable[item.dataIndex];
      return {
        id: data.id,
        index: item.dataIndex,
        name: data.first_name,
      };
    });
    setItemsDelete(operatorsDelete);
    setOpenDelete(true);
  };

  const updateDataTable = (data: Array<Operator>) => {
    setDataTable(data);
  };

  const rowsSelected = (): number[] => {
    return itemsDelete.map((item: ItemsToDelete): number => {
      return item.index;
    });
  };

  return (
    <div id="operators" style={{ padding: 30, paddingTop: 10 }}>
      <div style={{ marginBottom: 60 }}>
        <Button
          className={styles.buttonCreate}
          onClick={() => setOpenCreate(true)}
          size="large"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Criar
        </Button>
      </div>

      <MUIDataTable
        title={'Operadores'}
        data={dataTable}
        columns={columns}
        options={{
          filterType: 'checkbox',
          textLabels: MuiTableLabels,
          onRowsDelete: rowsDeleted => {
            const data = rowsDeleted.data;
            ActionDeleteOperators(data);
            return false;
          },
          rowsSelected: rowsSelected(),
        }}
      />

      <DeleteOperators
        itemsToDelete={itemsDelete}
        open={openDelete}
        handleClose={handleCloseDelete}
      />

      <CreateOperator open={openCreate} handleClose={handleCloseCreate} />

      <EditOperator open={openEdit} handleClose={handleCloseEdit} id={idOperatorSelected} />
    </div>
  );
}
