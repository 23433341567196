import { Operator } from '../interfaces/Operator';
import { CrudService } from './api/CrudService';

class OperatorService extends CrudService<Operator> {
  protected baseUrl(): string {
    return 'useroperator/';
  }
}

export default new OperatorService();
