import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { ObjectShape } from 'yup/lib/object';
import { ValidationError } from 'yup';
import { AlertMessage } from './../interfaces/AlertMessage';

interface TriggerAlertMessage {
  (data: AlertMessage | null): void;
}
class ValidationService {
  async validate<T extends unknown>(
    formRef: FormHandles | null,
    rules: { [key: string]: unknown },
  ): Promise<boolean> {
    try {
      formRef?.setErrors({});
      const schema = Yup.object().shape(rules as ObjectShape);
      await schema.validate(formRef?.getData(), { abortEarly: false });
      return true;
    } catch (error) {
      const validationErrors: Record<string, string> = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((erro: ValidationError) => {
          if (erro.path) validationErrors[erro.path] = erro.message;
        });
        formRef?.setErrors(validationErrors);
        return false;
      }
      return false;
    }
  }

  validateBackEndAlert(
    error: { status: number; data?: any },
    AlertMessage: TriggerAlertMessage,
    title = '',
  ) {
    if (error.status === 400) {
      if (error.data) {
        const dataError = error?.data;
        Object.keys(dataError).forEach(key => {
          dataError[key].forEach((item: string) => {
            AlertMessage({
              title: title,
              msg: `${key} : ${item}`,
              type: 'error',
            });
          });
        });
      }
    }
    if (error.status === 500) {
      AlertMessage({
        msg: 'Erro interno no servidor',
        type: 'error',
      });
    }
  }
}

export default new ValidationService();
