import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { BaseTextFieldProps, TextField, TextFieldProps, withStyles } from '@material-ui/core';
import { colors } from '../variables/Colors';

const TextFieldNuveo = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.primary,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: colors.primary,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.primary,
      },
    },
  },
})(TextField);

interface Props {
  name: string;
  onChangeValidate?: () => void;
  onChange?: (text: string) => void;
}

const Input: React.FC<Props & Record<string, unknown>> = ({
  name,
  onChangeValidate,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [valueInput, setValueInput] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setValueInput(defaultValue);
    }
  }, []);

  const handleOnChange = useCallback(
    e => {
      if (inputRef.current) {
        setValueInput(e.target.value);
        inputRef.current.value = e.target.value;
        if (onChange) onChange(e.target.value);
      }
      if (onChangeValidate) onChangeValidate();
    },
    [onChange],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref: HTMLFormElement) => {
        return ref.current.value;
      },
      setValue: (ref, value: string) => {
        setValueInput(value);
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <TextFieldNuveo
      inputProps={{ 'data-testid': 'input-field' }}
      error={error ? true : false}
      variant="outlined"
      name={fieldName}
      ref={inputRef}
      onChange={handleOnChange}
      defaultValue={defaultValue}
      value={valueInput}
      helperText={error ? error : ''}
      {...rest}
    />
  );
};

export default Input;
