import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { colors } from '../../variables/Colors';
import Header from './components/Header';
import SideBar from './components/Sidebar/SideBar';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useLoader } from '../../contexts/LoaderContext';
import Loader from '../Loader';

type Props = { children?: ReactNode };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(8),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      backgroundColor: colors.backgroundGrey,
      height: '100vh',
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 240,
    },
  }),
);

export default function Layout({ children }: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { loading } = useLoader();

  return (
    <div className={classes.root}>
      {loading && <Loader />}
      <section>
        <Header open={open} setOpen={setOpen} />
        <SideBar open={open} />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {children}
        </main>
      </section>
    </div>
  );
}
