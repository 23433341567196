import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useLoader } from '../contexts/LoaderContext';
import { AlertTitle } from '@material-ui/lab';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
}));

export default function AlertMessage(): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { alertMessage, triggerAlertMessage } = useLoader();

  useEffect(() => {
    if (alertMessage) {
      setOpen(true);
    }
  }, [alertMessage]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);

    setTimeout(() => {
      triggerAlertMessage(null);
    }, 100);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} className={classes.root}>
      <Alert data-testid="alert-msg" onClose={handleClose} severity={alertMessage?.type}>
        {alertMessage?.title && <AlertTitle>{alertMessage?.title}</AlertTitle>}
        {alertMessage?.msg}
      </Alert>
    </Snackbar>
  );
}
