export enum TaskStatus {
  PENDING = 1,
  COMPLETED = 2,
}

export enum SuccessStatus {
  PENDING = 1,
  SUCCESS = 2,
  ERROR = 3,
  UNDEFINED = 4,
}

export interface Task {
  result: any;
  metadata?: any;
  id: string;
  filesystem_files: Array<string>;
  deleted?: boolean;
  created_at: string;
  updated_at: string;
  filesystem_path: string;
  operation: number;
  status: TaskStatus.PENDING | TaskStatus.COMPLETED;
  project: string;
  operator?: string;
}

export interface AmountTasksProjectUser {
  project: string;
  total?: number;
  total_success?: number;
  operations: any;
}
