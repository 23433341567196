import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { Badge, Grid } from '@material-ui/core';
import ModalDocument from './ModalDocument';
import { useUserOperator } from '../../../contexts/UserOperatorContext';
import { DataSchemaModel } from '../../../interfaces/Schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 280,
      margin: '0 auto',
    },
    media: {
      height: 280,
    },
    button: {
      margin: '0 auto',
    },
    badge: {
      top: 3,
      right: 3,
      float: 'right',
      fontSize: '30px !important',

      '& .MuiBadge-badge': {
        fontSize: 20,
        width: 30,
        height: 30,
      },
    },
  }),
);

interface DocumentsProps {
  loading: boolean;
  setLoading: (status: boolean) => void;
  actionForm(data: DataSchemaModel, observation: string, success_status: number, error_fields: string[]): void;
  rejectTask(data: DataSchemaModel): void;
  displayReject: boolean;
}

export default function Documents(props: DocumentsProps) {
  const { loading, setLoading, actionForm, rejectTask, displayReject } = props;
  const classes = useStyles();
  const [index, setIndex] = useState<number>(0);

  const [showModalDocument, setShowModalDocument] = useState<boolean>(false);
  const { documents } = useUserOperator();

  useEffect(() => {
    if (!showModalDocument && !loading) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }, [showModalDocument]);

  const handleSelectDocument = (item: number) => {
    setShowModalDocument(true);
    setIndex(item);
  };

  const handleClose = () => {
    setShowModalDocument(false);
  };

  return (
    <Grid style={{ padding: 20 }} container spacing={3}>
      {documents.map((file, indexFile) => (
        <Grid key={file} xs={12} sm={6} md={3} data-testid="document" item>
          <div className={classes.root}>
            <Badge badgeContent={indexFile + 1} color="primary" className={classes.badge} />
            <Card>
              <CardActionArea onClick={() => handleSelectDocument(indexFile)}>
                <CardMedia className={classes.media} image={file} title="Image" />
              </CardActionArea>
            </Card>
          </div>
        </Grid>
      ))}

      {showModalDocument &&
        <ModalDocument
          documents={documents}
          index={index}
          handleClose={handleClose}
          actionForm={actionForm} 
          rejectTask={rejectTask}
          displayReject={displayReject}
        />}
    </Grid>
  );
}
