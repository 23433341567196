import { Observable } from 'rxjs';
import { Project } from '../interfaces/Project';
import { CrudService } from './api/CrudService';
import { HttpClient } from './api/HttpClient';
import { getUrl } from '../util/Helpers';

class ProjectService extends CrudService<Project> {
  protected baseUrl(): string {
    return 'projects/';
  }

  deleteOperation(id: string, operations: number[]): Observable<any> {
    return HttpClient.patch(getUrl(`${this.baseUrl()}${id}/remove_operation`), {
      operations,
    });
  }

}

export default new ProjectService();
