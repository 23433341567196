import React, { useEffect, useRef, useState } from 'react';
import ExtractionValidationService from '../../services/ExtractionValidationService';
import useStyles from './useStyles';
import { Form } from '@unform/web';
import Input from '../Input';
import { FormHandles } from '@unform/core';
import { Button, Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import RadioInputNuveo from '../RadioInputNuveo';
import { Props } from './props';
import { getLabel } from '../../util/Helpers';
import { SuccessStatus } from '../../interfaces/Task';
import {
  IExtractionValidation,
} from '../../interfaces/ExtractionValidation';

const FormBuilderEV = (props: Props): JSX.Element => {
  const { data, metadata, actionForm, previousAction, rejectTask, displayReject } = props;

  ExtractionValidationService.apiDataSet = data;
  const dataParsed = ExtractionValidationService.frontData;

  const [dataForm, changeDataForm] = useState(dataParsed);
  const [observation, setObservation] = useState("");
  const [changed, setChanged] = useState<string[]>([]);
  const classes = useStyles();
  const formRef = useRef<FormHandles>(null);

  const onSubmit = (dataEdited: IExtractionValidation): void => {
    const dataToBackend = ExtractionValidationService.parseToBack(dataEdited);
    if (previousAction) previousAction();
    actionForm(dataToBackend, observation, changed.length ? SuccessStatus.ERROR : SuccessStatus.SUCCESS, changed);
  };

  const onReject = (): void => {
    if (previousAction) previousAction();
    rejectTask(dataParsed);
  };

  useEffect(() => {
    if (dataForm) formRef?.current?.setData(dataForm);
  }, []);

  const handleChange = (field: string, value: any): void => {
    if (!changed.includes(field)) setChanged([...changed, field]);
    changeDataForm({ ...dataForm, [field]: value });
  };

  const fields = {
    text(item: Array<string>) {
      return (
        <Input
          key={item[0]}
          onChange={(e) => handleChange(item[0], e)}
          variant="outlined"
          margin="normal"
          fullWidth
          label={getLabel(item[0])}
          value={item[1]}
          name={item[0]}
        />
      );
    },
    boolean(item: Array<string>) {
      return <RadioInputNuveo 
        key={item[0]} 
        name={getLabel(item[0])} 
        value={item[1]} 
        onChange={(e) => 
        handleChange(item[0], e)} 
      />;
    },
  };

  return (
    <div>
      {dataForm && (
        <Form ref={formRef} className={classes.form} onSubmit={onSubmit}>
          {Object.entries(dataForm).map((item: string[]) => {
            if (item[0].includes('$bool')) {
              return fields.boolean(item);
            } else {
              return fields.text(item);
            }
          })}

          <Input
            key="observation"
            onChange={(e) => setObservation(e)}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Observação"
            value={observation}
            name="Observação"
          />

          {
            metadata? <>
              <p><b>Metadata</b></p>
              <p style={{ textAlign: "left" }}><pre >{ JSON.stringify(metadata, null, 2) }</pre></p>
            </> : null
          }

          <Button
            data-dataormid="submit-and-next"
            size="large"
            color="primary"
            className={classes.buttonForm}
            variant="contained"
            type="submit"
            fullWidth
          >
            Enviar e Próximo
          </Button>
          {
            displayReject &&
            <Button
              data-dataormid="submit-and-next"
              size="large"
              className={classes.buttonForm}
              style={{ backgroundColor: 'red' }}
              onClick={() => onReject()}
              color="secondary"
              variant="contained"
              fullWidth
            >
              Recusar
            </Button>
          }

        </Form>
      )}
    </div>
  );
};

export default FormBuilderEV;
