import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import StepsCreate from './StepsCreate';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useOperator } from '../../OperatorContext';
import SystemService from '../../../../../services/SystemService';

interface CreateOperatorProps {
  open: boolean;
  handleClose: () => void;
}

export default function CreateOperator(props: CreateOperatorProps) {
  const { open, handleClose } = props;

  const { changeInitialDataTask } = useOperator();

  useEffect(() => {
    loadInitialTasks();
  }, [])

  const loadInitialTasks = () => {
    SystemService.loadSystemsTasks().subscribe(response => {
      changeInitialDataTask(response);
    });
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ position: 'absolute', right: 0 }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-title">
        Cadastrar Operador
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <StepsCreate handleClose={handleClose} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
