import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Toolbar,
  AppBar,
} from '@material-ui/core';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useHistory, Link } from 'react-router-dom';
import { Props } from './props';
import TaskService from '../../../../services/TaskService';
import { useLoader } from '../../../../contexts/LoaderContext';
import { ArrowDropDown, ExitToApp, Person } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export default function Header(props: Props): JSX.Element {
  const classes = useStyles();
  const { open, setOpen } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { currentUser, setLogout } = useAuthContext();
  const { changeLoading } = useLoader();
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    changeLoading(true);
    TaskService.workOutAllTasks();
    setTimeout(() => {
      setAnchorEl(null);
      setLogout();
      history.push('/login');
      changeLoading(false);
    }, 500);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            data-testid="menu"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.title}>
            BackOffice
          </Typography>
          <Button
            aria-controls="simple-menu"
            data-testid="button-menu"
            color="inherit"
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
          >
            {currentUser?.email}
          </Button>
          <Menu
            id="simple-menu"
            data-testid="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link to='/conta' style={{ textDecoration: 'none', color: 'black' }}>
              <MenuItem data-testid="button-account">
                <ListItemIcon>
                  <Person fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Conta</Typography>
              </MenuItem>
            </Link>
            <MenuItem data-testid="button-exit" onClick={handleLogout}>
              <ListItemIcon>
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Sair</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
