import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';
import { DataTasks, ItemSelected } from './interfaces';
import Styles from './Styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { TreeItem } from '@material-ui/lab';

interface DefineTasksProps {
  dataTasks: DataTasks;
  setDataCallback: (data: DataTasks) => void;
}

export default function DefineTasks(props: DefineTasksProps): JSX.Element {
  const { setDataCallback, dataTasks } = props;
  const classes = Styles();
  const [dataDefineTasks, setDataDefineTasks] = useState<DataTasks>(dataTasks);

  useEffect(() => {
    setDataCallback(dataDefineTasks);
  }, [dataDefineTasks]);

  const InitialSystem = dataDefineTasks.systems[0];

  const [systemSelected, setSystemSelected] = useState<ItemSelected>({
    index: 0,
    name: InitialSystem?.name,
  });

  const [projectSelected, setProjectSelected] = useState<ItemSelected>({
    index: 0,
    name: InitialSystem.projects[0] ? InitialSystem.projects[0].name : ''
  });

  const [taskSelected, setTaskSelected] = useState<ItemSelected>({
    index: 0,
    name: InitialSystem.projects[0] && InitialSystem.projects[0].operations[0]
      ? InitialSystem.projects[0].operations[0].name : ''
  });

  const systems = dataDefineTasks.systems;
  const projects = systems[systemSelected.index].projects;

  const handleSystemSelect = (dataSystemSelected: ItemSelected, allowed: boolean) => {
    const newDataDefineTask = dataDefineTasks;
    const system = newDataDefineTask.systems[dataSystemSelected.index];

    system.allowed = allowed;

    if (!allowed) {
      system.projects.map(project => {
        project.allowed = allowed;
        project.operations.map(task => {
          task.allowed = allowed;
        });
      });
    }

    setSystemSelected(dataSystemSelected);
    setDataDefineTasks(newDataDefineTask);
  };

  const handleProjectSelect = (dataProjectSelected: ItemSelected, allowed: boolean) => {
    const newDataDefineTask = dataDefineTasks;
    const system = newDataDefineTask.systems[systemSelected.index];
    const project = system.projects[dataProjectSelected.index];

    project.allowed = allowed;

    project.operations.map(operation => {
      operation.allowed = allowed;
    });

    let defineSystemAllowed = false;
    system.projects.map(project => {
      if (project.allowed) {
        defineSystemAllowed = true;
      }
    });

    system.allowed = defineSystemAllowed ? true : false;

    setProjectSelected(dataProjectSelected);
    setDataDefineTasks(newDataDefineTask);
  };

  const handleTaskSelect = (dataProjectSelected: ItemSelected, dataTaskSelected: ItemSelected, allowed: boolean) => {
    const newDataDefineTask = dataDefineTasks;
    const system = newDataDefineTask.systems[systemSelected.index];
    const project = system.projects[dataProjectSelected.index];
    const operation = project.operations[dataTaskSelected.index];
    operation.allowed = allowed;

    if (allowed) {
      project.allowed = allowed;
      system.allowed = allowed;
    }

    setTaskSelected(dataTaskSelected);
    setDataDefineTasks(newDataDefineTask);
  };

  return (
    <Grid container spacing={1} xs={12}>
      <Grid item xs={5}>
        <List dense className={classes.root}>
          {systems.map((system, index) => {
            const labelId = `checkbox-list-system-label-${system.name}`;
            const dataSystemSelected = {
              index,
              name: system.name,
            };
            return (
              <ListItem
                onClick={() => setSystemSelected(dataSystemSelected)}
                selected={index == systemSelected.index}
                key={system.name}
                button
              >
                <ListItemText id={labelId} primary={system.name} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    color="primary"
                    onChange={() => {
                      handleSystemSelect(dataSystemSelected, !system.allowed);
                    }}
                    checked={system.allowed}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Grid>

      <Grid item xs={1}></Grid>

      <Grid item xs={6}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          <List dense className={classes.root}>
            {projects.map((project, index) => {
              const labelId = `checkbox-list-project-label-${project.name}`;
              const dataProjectSelected = {
                index,
                name: project.name,
              };
              return (
                <TreeItem
                  onClick={() => setProjectSelected(dataProjectSelected)}
                  nodeId={project.id}
                  key={project.id}
                  label={
                    <ListItem onClick={() => setProjectSelected(dataProjectSelected)} key={project.name} button>
                      <ListItemText id={labelId} primary={project.name} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          color="primary"
                          onChange={() => {
                            handleProjectSelect(dataProjectSelected, !project.allowed);
                          }}
                          checked={project.allowed}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  }
                >
                  <List key={project.name} dense className={classes.root}>
                    {project.operations.map((operation, index) => {
                      const labelId = `task-${project.name}-${operation.name}`;
                      const dataTaskSelected = {
                        index,
                        name: operation.name,
                      };

                      return (
                        <TreeItem
                          nodeId={`${project.name}-${operation.name}`}
                          key={`${project.name}-${operation.name}`}
                          label={
                            <ListItem
                              onClick={() => {
                                handleTaskSelect(dataProjectSelected, dataTaskSelected, !operation.allowed);
                              }}
                              key={`${project.name}-${operation.name}`}
                              button
                            >
                              <ListItemText id={labelId} primary={operation.name} />
                              <ListItemSecondaryAction>
                                <Checkbox
                                  edge="end"
                                  color="primary"
                                  onChange={() => {
                                    handleTaskSelect(dataProjectSelected, dataTaskSelected, !operation.allowed);
                                  }}
                                  checked={operation.allowed}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          }
                        ></TreeItem>
                      );
                    })}
                  </List>
                </TreeItem>
              );
            })}
          </List>
        </TreeView>
      </Grid>
    </Grid>
  );
}
