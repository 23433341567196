import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Props } from './props';
import { useAuthContext } from '../../contexts/AuthContext';

const RouteAdmin = (props: Props) => {
  const { layout: Layout, component: Component, ...rest } = props;
  const { isLoggedIn, currentUser } = useAuthContext();

  return isLoggedIn && currentUser?.is_admin ? (
    <Route
      exact
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  ) : (
    <Redirect to="/login" />
  );
};

export default RouteAdmin;
