import React from 'react';
import { makeStyles } from '@material-ui/core';
import OperatorUser from '../OperatorUser';
import { UserOperatorProvider } from '../../contexts/UserOperatorContext';

export default function Home(): JSX.Element {
  const useStyles = makeStyles({
    root: {
      width: '100%',
      textAlign: 'center',
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <UserOperatorProvider>
        <OperatorUser />
      </UserOperatorProvider>
    </div>
  );
}
