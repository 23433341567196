import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Drawer, CssBaseline, Toolbar, List, Divider, ListItem, ListItemText } from '@material-ui/core';
import { colors } from '../../../../variables/Colors';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DevicesIcon from '@material-ui/icons/Devices';
import { Props } from './props';
import { useLoader } from '../../../../contexts/LoaderContext';
import TaskService from '../../../../services/TaskService';

const drawerWidth = 240;

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: colors.primary,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    sideBarText: {
      color: colors.white,
    },
    icon: {
      color: '#fff',
    },
    sideBarContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'space-between',
    },
  }),
);

interface ItemSideBar {
  name: string;
  link: string;
  icon?: JSX.Element;
}
interface SideBarProps {
  item: ItemSideBar;
}

const dataSideBar = [
  {
    name: 'Meus Projetos',
    link: 'inicio',
    icon: <HomeIcon style={{ color: '#fff' }} />,
  },
];

const dataSideBarAdmin = [
  {
    name: 'Sistemas',
    link: 'sistemas',
    icon: <DevicesIcon style={{ color: '#fff' }} />,
  },
  {
    name: 'Operadores',
    link: 'operadores',
    icon: <GroupIcon style={{ color: '#fff' }} />,
  },
];

const ItemSideBar = (props: SideBarProps) => {
  const classes = useStyles();
  const { item } = props;
  return (
    <Link style={{ textDecoration: 'none' }} to={item.link} key={item.name}>
      <ListItem button>
        <ListItemIcon>{item?.icon}</ListItemIcon>
        <ListItemText primary={item.name} className={classes.sideBarText} />
      </ListItem>
      <Divider />
    </Link>
  );
};

export default function SideBar(props: Props): JSX.Element {
  const classes = useStyles();
  const { open } = props;
  const { changeLoading } = useLoader();
  const history = useHistory();
  const { currentUser, setLogout } = useAuthContext();

  const handleLogout = () => {
    changeLoading(true);
    TaskService.workOutAllTasks();
    setTimeout(() => {
      setLogout();
      history.push('/login');
      changeLoading(false);
    }, 500);
  };

  return (
    <>
      <CssBaseline />
      <Drawer
        data-testid="sidebar"
        className={classes.drawer}
        variant="persistent"
        open={Boolean(open)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.sideBarContent}>
          <div className={classes.drawerContainer}>
            <List>
              {dataSideBar.map((item: ItemSideBar) => (
                <ItemSideBar key={item.link} item={item} />
              ))}

              {currentUser?.is_admin &&
                dataSideBarAdmin.map((item: ItemSideBar) => (
                  <ItemSideBar key={item.link} item={item} />
                ))}
            </List>
          </div>

          <ListItem data-testid="logout" onClick={handleLogout} style={{ marginBottom: 5 }} button>
            <ListItemIcon>
              <ExitToAppIcon style={{ color: '#fff' }} />
            </ListItemIcon>
            <ListItemText primary={'Sair'} className={classes.sideBarText} />
          </ListItem>
        </div>
      </Drawer>
    </>
  );
}
