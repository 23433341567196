import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { useUserOperator } from '../../../contexts/UserOperatorContext';
import { OperationEnum } from '../../../interfaces/Project';
import { Grid, Tooltip } from '@material-ui/core';
import { DataSchemaModel } from '../../../interfaces/Schema';
import FormBuilder from '../../../components/FormBuilder/FormBuilder';
import FormBuilderEV from '../../../components/FormBuilderExtractionValidation/FormBuilderEV';

interface ModalDocumentProps {
  handleClose: () => void;
  index: number;
  documents: Array<string>;
  actionForm(data: DataSchemaModel, observation: string, success_status: number, error_fields: string[]): void;
  rejectTask(data: DataSchemaModel): void;
  displayReject: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '50px 20px 50px 10px',
      textAlign: 'center',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    documents: {
      display: 'flex',
      width: '100%',
      height: '80vh',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '1px solid #666',
    },
    builder: {
      marginLeft: 20,
    },
    imageDocument: {
      flexGrow: 1,
      maxHeight: '75vh',
      overflow: 'auto',
    },
    documentImage: {
      justifyContent: 'center',
      width: '90%',
      height: 'auto',
    },
    arrow: {
      width: 40,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalDocument(props: ModalDocumentProps) {
  const { handleClose, index, documents, actionForm, rejectTask, displayReject } = props;
  const classes = useStyles();
  const { project, operation, schema, task, changeDataForm } = useUserOperator();
  const [currentIndex, setCurrentIndex] = useState<number>(index);

  const handleNextDocument = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const handleBackDocument = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const previousAction = () => {
    changeDataForm({});
    handleClose();
  };
  const selectFormField = () => {
    if (operation === 4) {
      return (
        <FormBuilderEV
          data={task.result}
          metadata={task.metadata}
          actionForm={actionForm}
          previousAction={previousAction}
          rejectTask={rejectTask}
          displayReject={displayReject}
        />
      );
    }
    return (
      <FormBuilder
        data={schema?.data}
        metadata={task.metadata}
        actionForm={actionForm}
        previousAction={previousAction}
        rejectTask={rejectTask}
        displayReject={displayReject}
      />
    );
  };

  return (
    <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          {project && (
            <Typography variant="h6" className={classes.title}>
              {project.name} - {operation && Object.values(OperationEnum)[operation - 1]}
            </Typography>
          )}
          <Button autoFocus color="inherit" onClick={handleClose}>
            Fechar
          </Button>
        </Toolbar>
      </AppBar>

      <div className={classes.root}>
        <Grid container>
          <Grid item className={classes.documents} xs={12} md={8}>
            {currentIndex > 0 && (
              <div className={classes.arrow}>
                <Tooltip title="Anterior" aria-label="back">
                  <IconButton onClick={handleBackDocument} aria-label="Anterior">
                    <ArrowBackIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            <div className={classes.imageDocument}>
              <Typography variant="h6">Página - {currentIndex + 1}</Typography>
              {
                documents[currentIndex].endsWith('.pdf') ? 
                  <embed
                    src={documents[currentIndex]}
                    type="application/pdf"
                    height="800"
                    width="100%"
                  /> : <img className={classes.documentImage} src={documents[currentIndex]} />
              }
            </div>

            {currentIndex < documents.length - 1 && (
              <div className={classes.arrow}>
                <Tooltip title="Próximo" aria-label="next">
                  <IconButton
                    onClick={handleNextDocument}
                    style={{ float: 'right' }}
                    aria-label="Anterior"
                  >
                    <ArrowForwardIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <div style={{ textAlign: 'center' }}>
              {operation && <h2>{Object.values(OperationEnum)[operation - 1]}</h2>}
            </div>
            <div className={classes.builder}>
              {selectFormField()}
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
