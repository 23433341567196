import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  Typography 
} from '@material-ui/core';
import ModalProject from './components/ModalProject';
import ModalDeleteProject from './components/ModalDeleteProject';
import ItemProject from './components/ItemProject';
import { Project, Project as ProjectInterface } from '../../../interfaces/Project';
import { AmountTasksProjectUser } from '../../../interfaces/Task';
import AddIcon from '@material-ui/icons/Add';
import styles from './Projects.module.scss';
import { useLoader } from '../../../contexts/LoaderContext';
import ProjectService from '../../../services/ProjectService';
import NoDataFound from '../../../components/NoDataFound';

interface Props {
  projects: Project[];
  amountOperation: AmountTasksProjectUser[];
  handleProject: (project: Project, operation: number) => void;
  loadData: () => void;
}

export default function Projects(props: Props) {
  const { changeLoading } = useLoader();

  const { projects, amountOperation, handleProject, loadData } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<ProjectInterface>({} as ProjectInterface);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChangeInfo = (info = {} as Project) => {
    setInfoModal(info);
  };

  const handleOpenNewProject = () => {
    handleChangeInfo();
    handleOpen();
  };

  const listProjects = () => {
    loadData();
  }

  const handleOpenDelete = () => {
    setDeleteModal(true);
  };

  const handleCloseDelete = () => {
    setDeleteModal(false);
  };

  const handleDelete = async (id: string) => {
    changeLoading(true);
    ProjectService.delete(id).subscribe(
      () => {
        handleCloseDelete();
        changeLoading(false);
        listProjects();
      },
      error => {
        handleCloseDelete();
        changeLoading(false);
      },
    );
  };

  return (
    <div id="projects">
      <Typography variant="h5">
        Projetos
        <Button
          className={styles.buttonCreate}
          onClick={handleOpenNewProject}
          size="large"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Criar
        </Button>
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <b>Projeto</b>
              </TableCell>
              <TableCell align="center">
                <b>Atividades</b>
              </TableCell>
              <TableCell align="center">
                <b>Sistema</b>
              </TableCell>
              <TableCell align="center">
                <b>Acertos</b>
              </TableCell>
              <TableCell align="center">
                <b>Total</b>
              </TableCell>
              <TableCell align="center">
                <b>Ações</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map(item => (
              <ItemProject 
                key={item.id} 
                item={item} 
                amountOperation={
                  amountOperation.find(e => e.project === item.id)
                } 
                handleOpen={handleOpen} 
                handleChangeInfo={handleChangeInfo} 
                handleProject={handleProject}
                handleDelete={handleOpenDelete}
                listProjects={listProjects}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {projects.length == 0 && <NoDataFound />}

      <ModalProject open={showModal} handleClose={handleClose} info={infoModal} listProjects={listProjects} />
      <ModalDeleteProject open={deleteModal} handleClose={handleCloseDelete} info={infoModal} onDelete={handleDelete}/>
    </div>
  );
}
