import React from 'react';
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { DataSchemaModel } from '../../../../interfaces/Schema';
import NoDataFound from '../../../../components/NoDataFound';

interface ListSchemaProps {
  setSchemaList: (data: DataSchemaModel[]) => void;
  listItems: Array<DataSchemaModel>;
}

export default function ListSchema(props: ListSchemaProps): JSX.Element {
  const { listItems, setSchemaList } = props;

  const deleteItem = (label: string) => {
    const data = listItems
      .map(item => {
        if (item.label !== label) {
          return item;
        }
      })
      .filter(item => {
        if (item) return item;
      });

    setSchemaList(data as DataSchemaModel[]);
  };

  return (
    <div>
      {
        listItems.length > 0 ? (
          <Card>
            <List style={{ overflow: 'auto', maxHeight: 450 }}>
              {listItems.map((schema: DataSchemaModel) => {
                return (
                  <ListItem key={schema.label}>
                    <ListItemText primary={schema.label} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteItem(schema.label)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Card>
        ) : <NoDataFound type='info' variant="outlined" text="Nenhum schema definido ainda" />
      }

    </div>
  );
}
