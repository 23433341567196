import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { 
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import styles from '../Projects.module.scss';
import SelectNuveo from '../../../../components/SelectNuveo';
import Input from '../../../../components/Input';
import SystemService from '../../../../services/SystemService';
import ProjectUserOperatorService from '../../../../services/ProjectUserOperatorService';
import ValidationService from '../../../../services/ValidationService';
import ProjectService from '../../../../services/ProjectService';
import { useLoader } from '../../../../contexts/LoaderContext';
import { MessageValidation } from '../../../../util/MessageValidation';
import { colors } from '../../../../variables/Colors';
import { classGeneric } from '../../../../variables/Styles';
import { Project } from '../../../../interfaces/Project';
import { SelectNuveoItems } from '../../../../interfaces/SelectNuveoItems';
import { OperationEnum } from '../../../../interfaces/OperationEnum';
import { System } from '../../../../interfaces/System';

interface ModalProjectProps {
  open: boolean;
  handleClose: () => void;
  listProjects: () => void;
  info?: Project;
}

const getHours = (seconds?: number) => {
  if (!seconds) {
    return 0;
  }

  return Math.floor(seconds / 3600);
};

const getMinutes = (seconds?: number) => {
  if (!seconds) {
    return 0;
  }

  return Math.floor((seconds % 3600) / 60);
};

const getSeconds = (seconds?: number) => {
  if (!seconds) {
    return 0;
  }

  return seconds % 60;
};

export default function ModalProject(props: ModalProjectProps): JSX.Element {
  const { info, handleClose, listProjects, open } = props;

  const initialData = {
    name: info?.name,
    notification_url: info?.notification_url || "",
    use_default: true,
    operations: info?.operations,
    system: info?.system,
    hours: getHours(info?.notification_time),
    minutes: getMinutes(info?.notification_time),
    seconds: getSeconds(info?.notification_time),
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const formRef = useRef<FormHandles>(null);
  const [id, setId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [useDefault, setUseDefault] = useState<boolean>(true);
  const [reject, setReject] = useState<boolean>(false);
  const [isNewProject, setIsNewProject] = useState<boolean>(true);
  const [dataSystems, setDataSystems] = useState<Array<SelectNuveoItems>>([]);
  const { triggerAlertMessage } = useLoader();
  const classes = classGeneric();

  const [activities, setActivities] = useState<OperationEnum[] | number[]>([]);
  const operations = [
    OperationEnum.CLASSIFICATION,
    OperationEnum.EXTRACTION,
    OperationEnum.VALIDATION,
    OperationEnum.EXTRACTIONVALIDATION,
  ];

  async function setInitialData() {
    return initialData;
  }

  useEffect(() => {
    if (open) {
      loadSystems();
      if (info?.id) {
        setId(info?.id);
        setUseDefault(info?.use_default)
        setReject(info?.display_reject || false)
        setActivities(transformOperations());
        setIsNewProject(false);
        setInitialData().then(initialData => {
          formRef.current?.setData(initialData);
        });
      } else {
        setActivities([]);
        setIsNewProject(true);
      }
    }
  }, [open]);

  const submitForm = (data: Project) => {
    data.operations = prepareOperations(data);
    data.notification_time = prepareNotificationTime(data);
    data.use_default = useDefault;
    data.display_reject = reject;
    if (data.use_default) {
      data.notification_time = 60;
      data.notification_url = "";
    }
    validateFields().then(response => {
      if (response) {
        setLoading(true);
        isNewProject ? onSave(data) : onUpdate(data);
      }
    });
  };

  const prepareOperations = (data: Project) => {
    return operations.reduce((resp: number[], item, index) => {
      if (data.operations?.includes(item) || data.operations?.includes(index + 1)) {
        resp.push(index + 1);
      }

      return resp;
    }, []);
  };

  const prepareNotificationTime = (data: Project) => {
    const hours = data.hours ? Number(data.hours) * 3600 : 0;
    const minutes = data.minutes ? Number(data.minutes) * 60 : 0;
    const seconds = data.seconds ? Number(data.seconds) : 0;

    return (hours + minutes + seconds) || 60;
  };

  const transformOperations = () => {
    return operations.reduce((resp: OperationEnum[], item, index) => {
      if (info?.operations?.includes(index + 1)) {
        resp.push(Object.values(OperationEnum)[index]);
      }
      return resp;
    }, []);
  };

  const saveNewProjectOperationsUser = (data: any) => {
    ProjectUserOperatorService.save(data).subscribe(
      response => {
        finalizeProcessForm();
        return response;
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        return error;
      },
    );
  };

  const onSave = (data: Project) => {
    ProjectService.save(data).subscribe(
      response => {
        const project = response.id;
        const currentUser = localStorage.getItem("currentUser") || ".";
        const decoded_token = JSON.parse(atob(currentUser.split('.')[1]));
        const dataProjectUserOperator = {
          project,
          operations: data.operations,
          useroperator: decoded_token?.sub?.id || 0,
        };
        saveNewProjectOperationsUser(dataProjectUserOperator);
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        setLoading(false);
      },
    );
  };

  const onUpdate = (data: Project) => {
    ProjectService.update(id, { ...data, system: info?.system }).subscribe(
      response => {
        finalizeProcessForm();
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        setLoading(false);
      },
    );
  };

  const loadSystems = () => {
    SystemService.list().subscribe((response: System[]) => {
      const newDataSystem = response.map((item: System) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      setDataSystems(newDataSystem);
    });
  };

  const finalizeProcessForm = () => {
    listProjects();
    handleClose();
    setLoading(false);
    triggerAlertMessage({
      title: 'Projeto',
      msg: `${isNewProject ? 'Cadastrado' : 'Alterado'} com sucesso !`,
      type: 'success',
    });
  };

  const validateFields = () => {
    const rules = {
      system: Yup.string().required(MessageValidation.required),
      name: Yup.string().required(MessageValidation.required),
      operations: Yup.array().required(MessageValidation.required),
    };
    return ValidationService.validate(formRef?.current, rules);
  };

  return (
    <div>
      <Dialog open={open} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle
          style={{ textAlign: 'center' }}
          id="form-dialog-title"
          data-testid="form-dialog-title"
        >
          <b>{isNewProject ? 'Criar projeto' : `Editar projeto ${info?.name}`}</b>
        </DialogTitle>

        <Form ref={formRef} initialData={initialData} onSubmit={submitForm}>
          <DialogContent style={{ padding: 50 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={styles.formSpace}>
                <SelectNuveo name="system" label="System" items={dataSystems} />
              </Grid>

              <Grid item xs={12} className={styles.formSpace}>
                <Input label="Nome" name="name" fullWidth />
              </Grid>

              <Grid item xs={12} className={styles.formSpace}>
                <Autocomplete
                  limitTags={2}
                  multiple
                  id="checkboxes-tags-demo"
                  options={operations}
                  value={activities as OperationEnum[]}
                  onChange={(e, newValue) => {
                    setActivities(newValue);
                    formRef.current?.setFieldValue('operations', newValue);
                  }}
                  getOptionLabel={option => {
                    return parseInt(option)
                      ? Object.values(OperationEnum)[parseInt(option) - 1]
                      : option;
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        data-testid="select-option"
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        color="primary"
                        checked={selected}
                      />
                      {option}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <Input
                      {...params}
                      label="Atividades"
                      name="operations"
                      fullWidth
                      placeholder="Atividades...."
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={`${styles.formSpace} blue`}>
              <Grid container spacing={1} justifyContent="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      color="primary"
                      checked={reject}
                      onChange={() => setReject(!reject)}
                    />
                  }
                  label="Mostrar botão de rejeitar"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={`${styles.formSpace} blue`}>
              <Grid container spacing={1} justifyContent="center">
                <h2><b>Notificação</b></h2>
              </Grid>
            </Grid>

            <Grid item xs={12} className={`${styles.formSpace} blue`}>
              <Grid container spacing={1} justifyContent="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      color="primary"
                      checked={useDefault}
                      onChange={() => setUseDefault(!useDefault)}
                    />
                  }
                  label="Notificar Nuveo"
                />
              </Grid>
            </Grid>

            { 
              !useDefault ? <>
                <Grid item xs={12} className={styles.formSpace}>
                  <Input label="URL" name="notification_url" fullWidth />
                </Grid>

                <Grid item xs={12} className={styles.formSpace}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Input label="Horas" name="hours" type="number" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                      <Input label="Minutos" name="minutes" type="number" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                      <Input label="Segundos" name="seconds" type="number" fullWidth />
                    </Grid>
                  </Grid>
                </Grid>
              </> : null
            }

            <Grid container spacing={1}>
              <Grid item xs={6}>
                {!isNewProject && (
                  <Button
                    size="large"
                    style={{
                      backgroundColor: colors.danger,
                      color: 'white',
                    }}
                    fullWidth
                    variant="contained"
                    onClick={handleClose}
                    color="default"
                  >
                    Cancelar
                  </Button>
                )}
              </Grid>

              <Grid item xs={isNewProject ? 12 : 6}>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  color="primary"
                  id="modal-save"
                >
                  Salvar
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Form>
      </Dialog>
    </div>
  );
}
