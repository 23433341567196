import { Observable } from 'rxjs';
import { SchemaModel } from '../interfaces/Schema';
import { CrudService } from './api/CrudService';

class SchemaService extends CrudService<SchemaModel> {
  protected baseUrl(): string {
    return 'schemas/';
  }
}

export default new SchemaService();
