import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Input from '../../../../../components/Input';
import styles from '../../Operators.module.scss';
import { colors } from '../../../../../variables/Colors';
import { useOperator } from '../../OperatorContext';
import OperatorService from '../../../../../services/OperatorService';
import ValidationService from '../../../../../services/ValidationService';
import { MessageValidation } from '../../../../../util/MessageValidation';
import { useLoader } from '../../../../../contexts/LoaderContext';
import { Operator } from '../../../../../interfaces/Operator';
import { classGeneric } from '../../../../../variables/Styles';

interface EditDataOperatorProps {
  data: Operator;
  handleClose: () => void;
}

export default function EditDataOperador(props: EditDataOperatorProps) {
  const { handleClose, data } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { triggerAlertMessage } = useLoader();
  const { changeDataOperators, dataOperators } = useOperator();
  const classes = classGeneric();

  useEffect(() => {
    if (data) {
      formRef.current?.setData(data);
    }
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleEditDataOperator = (dataForm: Operator) => {
    validateFields().then(response => {
      if (response) {
        updateOperator(dataForm);
      }
    });
  };

  const updateOperator = (dataForm: Operator) => {
    setLoading(true);
    OperatorService.update(data.id, dataForm).subscribe(
      operator => {
        updateDataOperator(operator);
        setLoading(false);
        triggerAlertMessage({
          type: 'success',
          msg: 'Operador atualizado com sucesso',
        });
        handleClose();
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        setLoading(false);
      },
    );
  };

  const updateDataOperator = (operator: Operator) => {
    const newDataOperator = Array();
    dataOperators.map(item => {
      const newOperator = item.id === operator.id ? operator : item;
      newDataOperator.push(newOperator);
    });
    changeDataOperators(newDataOperator);
  };

  const validateFields = () => {
    const rules = {
      first_name: Yup.string().required(MessageValidation.required),
      username: Yup.string().required(MessageValidation.required),
      email: Yup.string().required(MessageValidation.required).email(MessageValidation.email),
    };
    return ValidationService.validate(formRef?.current, rules);
  };

  return (
    <Form ref={formRef} initialData={data} onSubmit={handleEditDataOperator}>
      <Grid container xs={12}>
        <Grid item xs={12} className={styles.formSpace}>
          <Input fullWidth variant="outlined" name="first_name" label="Nome" />
        </Grid>

        <Grid item xs={12} className={styles.formSpace}>
          <Input fullWidth variant="outlined" name="email" label="E-mail" />
        </Grid>

        <Grid item xs={12} className={styles.formSpace}>
          <Input fullWidth variant="outlined" name="username" label="Username" />
        </Grid>

        <Grid style={{ display: 'none' }}>
          <Input fullWidth variant="outlined" name="operations" label="operations" />
        </Grid>

        <Grid item xs={12}>
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={handleClose}
              style={{
                backgroundColor: colors.danger,
                marginRight: 8,
                color: 'white',
              }}
            >
              Cancelar
            </Button>
            <Button disabled={loading} type="submit" variant="contained" color="primary">
              Salvar
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
