import React from 'react';
import { OperatorProvider } from './OperatorContext';
import Operators from './Operators';

export default function index() {
  return (
    <OperatorProvider>
      <Operators />
    </OperatorProvider>
  );
}
