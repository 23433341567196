import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { classGeneric } from '../../../../variables/Styles';
import Input from '../../../../components/Input';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { colors } from '../../../../variables/Colors';
import { MessageValidation } from '../../../../util/MessageValidation';
import ValidationService from '../../../../services/ValidationService';
import SelectNuveo from '../../../../components/SelectNuveo';
import { SelectNuveoItems } from '../../../../interfaces/SelectNuveoItems';
import ListSchema from './ListSchema';
import SchemaService from '../../../../services/SchemaService';
import { useLoader } from '../../../../contexts/LoaderContext';
import SelectOptions from './SelectOptions';
import {
  DataSchemaModel,
  SchemaInfo,
  SchemaModel,
  SchemaOption,
} from '../../../../interfaces/Schema';

interface ModalSchemaProps {
  open: boolean;
  handleClose: () => void;
  handleNewSchema: (operation: number) => void;
  info: SchemaInfo;
}

export default function ModalSchema(props: ModalSchemaProps): JSX.Element {
  const { info, handleClose, open } = props;
  const classes = classGeneric();

  const { triggerAlertMessage } = useLoader();
  const [currentSchema, setCurrentSchema] = useState<SchemaModel>({} as SchemaModel);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectField, setSelectField] = useState<boolean>(false);
  const [required, setRequired] = useState({ checked: true });

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequired({ ...required, [event.target.name]: event.target.checked });
  };

  const operations: SelectNuveoItems[] = [
    { label: 'Classificação', value: 1 },
    { label: 'Extração', value: 2 },
    { label: 'Validação', value: 3 },
  ];
  const types: SelectNuveoItems[] = [
    { label: 'Booliano', value: 'bool' },
    { label: 'Texto', value: 'text' },
    { label: 'Número', value: 'number' },
    { label: 'Select', value: 'select' },
  ];
  const [schemaList, setSchemaList] = useState<DataSchemaModel[]>([]);
  const [optionsList, setOptionsList] = useState<SchemaOption[]>([]);
  const formRef = useRef<FormHandles>(null);

  function onSubmit() {
    setLoading(true);
    const data: SchemaModel = {
      operation: info.operation,
      data: schemaList,
      project: info.id,
    };
    if (currentSchema.id) {
      updateSchema(data);
    } else {
      saveSchema(data);
    }
  }

  const saveSchema = (data: SchemaModel) => {
    SchemaService.save(data).subscribe(
      response => {
        finalizeProcessForm();
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        setLoading(false);
      },
    );
  };

  const updateSchema = (data: SchemaModel) => {
    if (currentSchema.id) {
      SchemaService.update(currentSchema.id, data).subscribe(
        response => {
          finalizeProcessForm();
        },
        error => {
          ValidationService.validateBackEndAlert(error, triggerAlertMessage);
          setLoading(false);
        },
      );
    }
  };

  const loadSchema = () => {
    setLoading(true);
    setCurrentSchema({} as SchemaModel);
    setSchemaList([]);

    SchemaService.list({ project: info.id, operation: info.operation }).subscribe(
      (response: SchemaModel[]) => {
        const activity = response.find((item: SchemaModel) => item.operation === info.operation);
        if (activity) {
          setCurrentSchema(activity);
          setSchemaList(activity.data);
        }
        setLoading(false);
      },
      error => {
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
        setLoading(false);
      },
    );
  };

  const addDataSchema = (data: DataSchemaModel) => {
    validateFields().then(response => {
      if (response) {
        const newSchema: DataSchemaModel = {
          type: data.type,
          label: data.label,
          name: data.name,
          required: required.checked,
        };

        if (selectField) {
          newSchema.options = optionsList;
          setOptionsList([]);
          setSelectField(false);
        }

        setSchemaList([...schemaList, newSchema]);

        formRef.current?.setData({
          type: '',
          label: '',
          name: '',
          required: true,
        });
      }
    });
  };

  const validateFields = () => {
    const rules = {
      type: Yup.string().required(MessageValidation.required),
      label: Yup.string().required(MessageValidation.required),
      name: Yup.string().required(MessageValidation.required),
    };
    return ValidationService.validate(formRef?.current, rules);
  };

  const finalizeProcessForm = () => {
    handleClose();
    setLoading(false);
    triggerAlertMessage({
      title: 'Schema',
      msg: `Salvo com sucesso !`,
      type: 'success',
    });
  };

  const getOperationName = (): string | number | undefined => {
    return operations.find(item => item.value === info.operation)?.label;
  };

  const handleSelect = (type: string) => {
    if (type === 'select') {
      setSelectField(true);
    } else {
      setSelectField(false);
    }
  };

  const handleOptionList = (data: SchemaOption[]) => {
    setOptionsList(data);
  };

  useEffect(() => {
    if (info) loadSchema();
  }, [info]);

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth={'md'} aria-labelledby="form-dialog-title">
        {info && (
          <DialogTitle style={{ textAlign: 'center', marginTop: 30 }} id="form-dialog-title">
            Gerenciar Esquema de {getOperationName()}
          </DialogTitle>
        )}

        <DialogContent style={{ paddingBottom: 80 }}>
          <Grid container justify="center" spacing={4} style={{ padding: 30 }}>
            <Grid item sm={6}>
              <Form ref={formRef} onSubmit={addDataSchema}>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <SelectNuveo
                        onChange={handleSelect}
                        data-testid="selectField"
                        name="type"
                        label="Tipo"
                        items={types}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input id="label" label="Nome do campo" name="label" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Input id="name" label="ID Backend" name="name" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="required"
                            checked={required.checked}
                            onChange={handleCheckChange}
                            name="checked"
                            color="primary"
                          />
                        }
                        label="Campo obrigatório?"
                      />
                    </Grid>
                    {selectField && (
                      <SelectOptions
                        optionsList={optionsList}
                        handleOptionList={handleOptionList}
                      />
                    )}

                    <Grid item xs={12}>
                      <Button
                        size="large"
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Grid>

            <Grid item sm={6} style={{ marginTop: 11 }}>
              {!loading && <ListSchema listItems={schemaList} setSchemaList={setSchemaList} />}
              {loading && (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress size={24} />
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{
              backgroundColor: colors.danger,
              marginRight: 8,
              color: 'white',
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            type="submit"
            color="primary"
            disabled={loading}
          >
            Salvar
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
