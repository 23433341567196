import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

interface Items {
  value: string | number;
  label: string | number;
}

interface SelectNuveoProps {
  name: string;
  label: string;
  items: Array<Items>;
  onChangeValidate?: () => void;
  onChange?: (value: string) => void;
}

const SelectNuveo: React.FC<SelectNuveoProps> = ({
  name,
  label,
  items,
  onChangeValidate,
  onChange,
  ...rest
}) => {
  const inputRef = useRef({ value: '' });
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [valueInput, setValueInput] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setValueInput(defaultValue);
    }
  }, []);

  const handleOnChange = useCallback(
    e => {
      if (inputRef.current) {
        setValueInput(e.target.value);
        inputRef.current.value = e.target.value;

        if (onChange) onChange(e.target.value);
      }
      if (onChangeValidate) onChangeValidate();
    },
    [onChange],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref: HTMLFormElement) => {
        return ref.current.value;
      },
      setValue: (ref, value: string) => {
        setValueInput(value);
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <FormControl
      data-testid="nuveoSelectFormControl"
      fullWidth
      variant="outlined"
      error={error ? true : false}
      margin="normal"
    >
      <InputLabel id="select-outlined" data-testid="nuveoSelectFieldLabel">
        {label}
      </InputLabel>
      <Select
        labelId="nuveo-select-label"
        id="nuveo-select"
        data-testid="nuveoSelectField"
        value={valueInput}
        label={label}
        onChange={handleOnChange}
        inputProps={{
          name: 'nuveoSelectName',
          id: 'nuveoSelectId',
          'data-testid': 'select-input',
        }}
        {...rest}
      >
        <MenuItem></MenuItem>
        {items.map((item: Items) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default SelectNuveo;
