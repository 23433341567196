import { Observable } from 'rxjs';
import { StatusEnum } from '../consts/Status';
import { Task } from '../interfaces/Task';
import { getUrl } from '../util/Helpers';
import { CrudService } from './api/CrudService';
import { HttpClient } from './api/HttpClient';

class TaskService extends CrudService<Task> {
  protected baseUrl(): string {
    return 'tasks/';
  }

  workOn(id: string): Observable<Task> {
    return HttpClient.patch(`${getUrl(this.baseUrl())}${id}/workon`, {});
  }

  workOut(id: string): Observable<Task> {
    return HttpClient.patch(`${getUrl(this.baseUrl())}${id}/workout`, {});
  }

  workOutAllTasks() {
    const params = { status: StatusEnum.PROCESSANDO };
    this.list(params).subscribe(tasks => {
      if (tasks.length > 0) {
        tasks.forEach(task => {
          this.workOut(task.id).subscribe(
            response => {
              return response;
            },
            error => {
              return error;
            },
          );
        });
      }
    });
  }
}

export default new TaskService();
