import { Observable } from 'rxjs';
import { DeleteService } from '../../interfaces/DeleteService';
import { ListService } from '../../interfaces/ListService';
import { SaveService } from '../../interfaces/SaveService';
import { SearchService } from '../../interfaces/SearchService';
import { UpdateService } from '../../interfaces/UpdateService';
import { getUrl } from '../../util/Helpers';
import { HttpClient } from './HttpClient';

export abstract class CrudService<T>
  implements ListService<T>, SearchService<T>, SaveService<T>, UpdateService<T>, DeleteService
{
  protected abstract baseUrl(): string;

  list(params?: any) {
    return HttpClient.get<Array<T>>(getUrl(this.baseUrl()), {
      params,
    });
  }

  search(id: number | string, params?: Partial<T>) {
    return HttpClient.get<T>(getUrl(this.baseUrl(), String(id)), {
      params,
    });
  }

  save(data: T): Observable<T> {
    return HttpClient.post(getUrl(this.baseUrl()), data);
  }

  update(id: number | string, data: Partial<T>) {
    return HttpClient.patch(getUrl(this.baseUrl(), String(id)), data);
  }

  delete(id: number | string) {
    return HttpClient.delete(getUrl(this.baseUrl(), String(id)));
  }
}
