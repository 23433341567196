import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumOperations } from '../consts/Operations';
import { OperationsPermissionProject, ProjectUserOperator } from '../interfaces/Operator';
import { EditProjectUserOperator } from '../interfaces/Project';
import { AmountTasksProjectUser } from '../interfaces/Task';
import { DataTasks, DefineTasks } from '../pages/admin/Operators/components/DefineTasks/interfaces';
import { getUrl } from '../util/Helpers';
import { CrudService } from './api/CrudService';
import { HttpClient } from './api/HttpClient';

class ProjectUserOperatorService extends CrudService<ProjectUserOperator> {
  protected baseUrl(): string {
    return 'projectuseroperator';
  }

  getAmountTasksProjectsUser(): Observable<AmountTasksProjectUser[]> {
    return HttpClient.get(getUrl(`${this.baseUrl()}/amout_tasks`));
  }

  /** Prepares the frontend data in the ideal format for requesting create ProjectUserOperations  */
  prepareDataDefinedTasks(dataTask: DataTasks, idUserOperator: number | string) {
    const dataProjectsUserOperator = Array();
    dataTask.systems.forEach(system => {
      system.projects.forEach(project => {
        if (project.allowed) {
          const operationProject: Array<number> = [];
          project.operations.forEach(item => {
            if (item.allowed) operationProject.push(item.id);
          });
          dataProjectsUserOperator.push({
            project: project.id,
            useroperator: idUserOperator,
            operations: operationProject,
          });
        }
      });
    });
    return dataProjectsUserOperator;
  }

  /** Lists userOperator's ProjectUserOperations and returns an observable
   * in the OperationsPermissionProject structure */
  getTasksOperators(params: {
    useroperator: string;
  }): Observable<Array<OperationsPermissionProject>> {
    return this.list(params).pipe(
      map(response => {
        const newDataUserOperations: Array<OperationsPermissionProject> = Array();
        response.map(item => {
          newDataUserOperations.push({
            id: item.id,
            project_id: item.project_data.id,
            operations: this.prepareOperations(item.project_data.operations, item.operations),
          });
        });
        return newDataUserOperations;
      }),
    );
  }

  /** Prepares the operations in the structure we use in the frontend  */
  prepareOperations(
    operations?: Array<number | string>,
    operationsPermission?: Array<number | string>,
  ): Array<DefineTasks> {
    const finalDataOperations = Array();
    if (operations && operationsPermission) {
      operations.forEach(operation => {
        if (typeof operation === 'number') {
          const objectOperation: DefineTasks = {
            id: operation,
            name: EnumOperations[operation - 1],
            allowed: !!operationsPermission.find(element => element == operation),
          };
          finalDataOperations.push(objectOperation);
        }
      });
    }
    return finalDataOperations;
  }

  /** Set the operations data of the selected user in the front end structure */
  defineTasksUserOperator(
    dataTask: DataTasks,
    setDataTask: React.Dispatch<React.SetStateAction<DataTasks>>,
    operations: Array<OperationsPermissionProject>,
  ) {
    dataTask.systems.forEach(system => {
      system.projects.forEach(project => {
        operations.forEach(element => {
          if (element.project_id == project.id) {
            project.operations = element.operations;
            if (element.operations.find(operation => operation.allowed)) {
              project.allowed = true;
              system.allowed = true;
            }
          }
        });
      });
    });
    setDataTask(dataTask);
  }

  prepareCurrentProjectOperation(
    currentProjectOperationUser: Array<OperationsPermissionProject>,
  ): EditProjectUserOperator[] {
    return currentProjectOperationUser.map(item => {
      const operations = Array();
      item.operations.forEach(operation => {
        if (operation.allowed) operations.push(operation.id);
      });
      return {
        id: item.id,
        project: item.project_id,
        operations: operations,
      };
    });
  }
}

export default new ProjectUserOperatorService();
