import { Provider } from '../interfaces/ServiceProvider';
import { Container } from '../services/Container';
const crypto = require('crypto');

export function getUrl(baseUrl: string, ...path: string[]): string {
  path = wrapIntoArray(path);
  const extra = path.length ? path.reduce((previous, current) => previous + '/' + current) : '';
  const fullUrl = baseUrl.endsWith('/') || extra === '' ? baseUrl + extra : baseUrl + '/' + extra;
  return fullUrl;
}

/**
 * Resolves an instance from the container service
 *
 * @param provider The dependency provider to be resolved
 * @return The resolved service
 */
export function make<Type>(provider: Provider<Type>): Type {
  return Container.getInstance().get(provider);
}

export function blank<Type>(value: Type): boolean {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === 'string') {
    return value.trim().length === 0;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (value instanceof ArrayBuffer) {
    return value.byteLength === 0;
  }
  return false;
}

export function wrapIntoArray<T>(value: string[]): string[] {
  if (blank(value)) return [];
  if (Array.isArray(value)) return value;
  return [value];
}

export function consoleLog<T>(...args: T[]): void {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
}

export function checkParams<T>(params: T, keys: Array<keyof T>): boolean {
  if (params) {
    for (const element of keys) {
      if (params[element] === undefined || params[element] === null) {
        return false;
      }
    }
  } else {
    return false;
  }

  return true;
}

export function prepareData<T>(data: T): Partial<T> {
  const finalData = new Object() as Partial<T>;
  for (const key in data) {
    if (data[key] !== undefined) {
      finalData[key] = data[key];
    }
  }
  return finalData;
}

export function keyEnter(event: KeyboardEvent, action: () => void): void {
  if (event.key === 'Enter') {
    action();
  }
}

export function generatePassword() {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJLMNOPQRSTUVWXYZ!@#$%^&*()+?><:{}[]';
  const passwordLength = 16;
  let password = '';

  for (let i = 0; i < passwordLength; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }

  return password;
}

export function generateJWT(subject = {}) {
  const header = JSON.stringify({
    alg: 'HS256',
    typ: 'JWT',
  });

  const payload = JSON.stringify({
    sub: subject,
  });

  const base64Header = Buffer.from(header).toString('base64').replace(/=/g, '');
  const base64Payload = Buffer.from(payload).toString('base64').replace(/=/g, '');

  const data = base64Header + '.' + base64Payload;

  return data;
}

export function compareArray(array1: [], array2: []) {
  return array1.sort().join(',') === array2.sort().join(',');
}

const labels: Record<string,string> = {
  HasDate: "Tem Data?",
  HasSignature: "Tem Assinatura?",
  HasStamp: "Tem Carimbo?",
  Number: "Número",
  ValidNumber: "Número Válido?",
  AccessKey: "Chave Acesso",
  CompanyCnpj: "CNPJ Emissor",
  CompanyName: "Nome Emissor",
  CustomerCnpjCpf: "CNPJ CPF Tomador",
  CustomerName: "Nome Tomador",
  IssueDate: "Data Emissão",
  Total: "Total",
  Date: "Data",
  Plate: "Placa",
  Time: "Horário",
  Weight: "Peso",
};

export function getLabel(original: string): string {
  return labels[original] || original;
};