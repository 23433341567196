import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { FormControl, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useField } from '@unform/core';

interface IRadioInputNuveo {
  name: string;
  label: string;
  value?: string;
  onChange: (value: boolean) => void;
}

export default function RadioInputNuveoBool(props: IRadioInputNuveo) {
  const { name, label, onChange } = props;
  const inputRef = useRef({ value: false });
  const { fieldName, defaultValue, registerField } = useField(name);
  const [valueInput, setValueInput] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValue) {
      setValueInput(defaultValue);
    }
  }, []);

  const handleOnChange = useCallback(
    e => {
      if (inputRef.current) {
        const value = e.target.value === 'true';
        setValueInput(value);
        inputRef.current.value = value;

        if (onChange) onChange(value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref: HTMLFormElement) => {
        return ref.current.value;
      },
      setValue: (ref, value: string) => {
        setValueInput(value === 'true');
        ref.current.value = value === 'true';
      },
      clearValue: ref => {
        ref.current.value = false;
      },
    });
  }, [fieldName, registerField]);

  return (
    <FormControl fullWidth>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        style={{
          marginTop: '5px',
        }}
      >
        <FormLabel
          style={{ textAlign: 'start', fontSize: '1rem', fontWeight: 'medium' }}
          component="legend"
        >
          {label + ':'}
        </FormLabel>
        <RadioGroup
          aria-label="gender"
          name="controlled-radio-buttons-group"
          value={valueInput}
          onChange={handleOnChange}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 10,
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Verdadeiro" />
            <FormControlLabel value={false} control={<Radio />} label="Falso" />
          </Box>
        </RadioGroup>
      </Box>
    </FormControl>
  );
}
