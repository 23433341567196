import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import RouteOperator from './components/RouteWithLayout/RouteOperator';
import RouteAdmin from './components/RouteWithLayout/RouteAdmin';
import Layout from './components/Layout';
import { Home, Login, Reset, Account } from './pages';
import { System, Operators } from './pages/admin';
import RouteStatus from './components/RouteWithLayout/RouteStatus';

const NoMatchPage = () => {
  return (
    <Redirect
      to={{
        pathname: '/inicio',
      }}
    />
  );
};

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset" component={Reset} />
        <RouteOperator layout={Layout} path="/inicio" component={Home} />
        <RouteOperator layout={Layout} path="/conta" component={Account} />
        <RouteAdmin layout={Layout} path="/sistemas" component={System} />
        <RouteAdmin layout={Layout} path="/operadores" component={Operators} />
        <Route exact path="/" component={NoMatchPage} />
        <RouteStatus path="/503" status={503} />
        <RouteStatus status={404} />
      </Switch>
    </BrowserRouter>
  );
}
