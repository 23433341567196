import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Input from '../../components/Input';
import Container from '@material-ui/core/Container';
import { MessageValidation } from '../../util/MessageValidation';
import ValidationService from '../../services/ValidationService';

import { colors } from '../../variables/Colors';
import AuthService from '../../services/AuthService';
import { FormHandles } from '@unform/core';
import { useLoader } from '../../contexts/LoaderContext';
import Loader from '../../components/Loader';
import { AlertMessage } from '../../interfaces/AlertMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { UserResponse } from '../../interfaces/User';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    height: '100vh',
    backgroundColor: colors.primary,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '272px',
    marginBottom: '20px',
  },
  form: {
    width: '442px',
    marginTop: theme.spacing(1),
  },
  input: {
    backgroundColor: colors.white,
    borderRadius: '5px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.secondary,
    color: colors.white,
    fontWeight: 600,
    fontSize: '24px',

    '&:hover': {
      backgroundColor: '#058ac1',
    },
  },
}));

export interface LoginData {
  username: string;
  password: string;
}

export default function Login(): JSX.Element {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { loading, changeLoading, triggerAlertMessage } = useLoader();
  const { isLoggedIn, setLogin } = useAuthContext();

  useEffect(() => {
    isLoggedIn && history.push('/');
  });

  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const onSubmit = (data: LoginData): void => {
    validateFields().then(response => {
      if (response) {
        changeLoading(true);
        AuthService.login(data).subscribe(
          response => {
            setLogin(response);
            changeLoading(false);
            history.push('/');
          },
          err => {
            const errorData: AlertMessage = {
              msg: 'Ocorreu um erro inesperado ao fazer login. Tente novamente.',
              type: 'error',
            };

            if (err.status === 400) {
              errorData.msg = 'Não foi possível fazer login com as credenciais fornecidas.';
            }

            triggerAlertMessage(errorData);
            changeLoading(false);
          },
        );
      }
    });
  };

  const validateFields = (): Promise<boolean> => {
    const rules = {
      username: Yup.string().required(MessageValidation.required),
      password: Yup.string().required(MessageValidation.required),
    };
    return ValidationService.validate(formRef?.current, rules);
  };

  return (
    <Container component="main" className={classes.main}>
      <div className={classes.paper}>
        <div className={classes.logo}>
          <img
            src="https://assets.nuveo.ai/logos/transparent_white.png"
            alt="Nuveo logo"
            width="272"
          />
        </div>

        <Form ref={formRef} className={classes.form} onSubmit={onSubmit}>
          <Input
            variant="filled"
            margin="normal"
            fullWidth
            id="email"
            label="Usuário"
            name="username"
            autoComplete="email"
            autoFocus
            className={classes.input}
          />
          <Input
            variant="filled"
            margin="normal"
            fullWidth
            name="password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            className={classes.input}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Visualizar" aria-label="show">
                    <IconButton
                      aria-label="toggle password visibility"
                      data-testid="toggle-password"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <Link style={{ textDecoration: 'none', color: 'white' }} to="/reset">Esqueceu a senha?</Link>
          <Button
            data-testid="btn-click"
            type="submit"
            fullWidth
            disableRipple
            className={classes.submit}
          >
            Entrar
          </Button>
        </Form>
      </div>
      {loading && <Loader />}
    </Container>
  );
}
