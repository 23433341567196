const MuiTableLabels = {
  body: {
    noMatch: 'Desculpe, não foi encontrado nenhum resultado',
    toolTip: 'Ordenar',
  },
  pagination: {
    next: 'Próxima Página',
    previous: 'Página Anterior',
    rowsPerPage: 'Linhas por página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Pesquisar',
    downloadCsv: 'Baixar CSV',
    print: 'Imprimir',
    viewColumns: 'Visualizar Colunas',
    filterTable: 'Filtrar Tabela',
  },
  filter: {
    all: 'Todos',
    title: 'Filtros',
    reset: 'Resetar',
  },
  viewColumns: {
    title: 'Visualizar Colunas',
    titleAria: 'Visualizar/Esconder colunas da tabela',
  },
  selectedRows: {
    text: 'linha(s) selecionadas',
    delete: 'Deletar',
    deleteAria: 'Delete Selected Rows',
  },
};

export { MuiTableLabels };
