import React, { useState, useRef } from 'react';
import { 
  makeStyles,
  Container,
  Card,
  CardContent,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  TextField,
} from '@material-ui/core';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLoader } from '../../contexts/LoaderContext';
import { useHistory } from 'react-router-dom';
import { colors } from '../../variables/Colors';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { MessageValidation } from '../../util/MessageValidation';
import { AlertMessage } from '../../interfaces/AlertMessage';
import Loader from '../../components/Loader';
import Input from '../../components/Input';
import ValidationService from '../../services/ValidationService';
import ResetService from '../../services/ResetService';
import moment from 'moment';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'center',
  },
  card: {
    margin: theme.spacing(3, 0, 2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.primary,
    color: colors.white,
    '&:hover': {
      backgroundColor: '#058ac1',
    },
    '&:disabled': {
      backgroundColor: '#058ac1',
      color: colors.white,
    },
  },
  form: {
    width: '442px',
    marginTop: theme.spacing(1),
  },
  row: {
    marginTop: theme.spacing(2),
  },
  input: {
    backgroundColor: colors.white,
    borderRadius: '5px',
  },
}));

export interface ChangeData {
  email: string;
  password: string;
  current_password: string;
}

export default function Home(): JSX.Element {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { loading, changeLoading, triggerAlertMessage } = useLoader();

  const { currentUser } = useAuthContext();

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const onSubmit = (data: ChangeData): void => {
    validateFields().then(response => {
      if (response) {
        changeLoading(true);
        ResetService.change({
          ...data,
          email: currentUser?.email || "",
        }).subscribe(
          () => {
            changeLoading(false);
            const msgData: AlertMessage = {
              msg: 'Senha alterada com sucesso.',
              type: 'success',
            };
            triggerAlertMessage(msgData);
            history.push('/');
          },
          err => {
            const errorData: AlertMessage = {
              msg: 'Ocorreu um erro inesperado ao alterar a senha. Tente novamente.',
              type: 'error',
            };

            if (err.status === 400) {
              errorData.msg = 'Não foi possível fazer alterar a senha com as informações fornecidas.';
            }

            triggerAlertMessage(errorData);
            changeLoading(false);
          },
        );
      }
    });
  }

  const getDate = (date: string): string => {
    const dt = new Date(date);
    return moment(dt).format('YYYY-MM-DD - HH:mm');
  }

  const validateFields = (): Promise<boolean> => {
    const rules = {
      current_password: Yup.string().required(MessageValidation.required),
      password: Yup.string().required(MessageValidation.required).min(8, MessageValidation.min),
      confirmPassword: Yup.string()
        .required(MessageValidation.required)
        .min(8, MessageValidation.min)
        .oneOf([Yup.ref('password'), null], MessageValidation.password),
    };
    return ValidationService.validate(formRef?.current, rules);
  };

  return (
    <Container component="main" className={classes.root}>
      <Typography variant="h5">
        Conta
      </Typography>
      <Card className={classes.card}>
        <CardContent>
          <Grid container className={classes.row}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography variant="h6" color="inherit" align="left">
                <b>Username</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="left">
                <TextField
                  value={currentUser?.username}
                  label="Username"
                  disabled
                  variant="outlined"
                  fullWidth
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography variant="h6" color="inherit" align="left">
                <b>Email</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="left">
                <TextField
                  value={currentUser?.email}
                  label="Email"
                  disabled
                  variant="outlined"
                  fullWidth
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography variant="h6" color="inherit" align="left">
                <b>Nome</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="left">
                <TextField
                  value={ `${currentUser?.firstName} ${currentUser?.lastName}` }
                  label="Nome"
                  disabled
                  variant="outlined"
                  fullWidth
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography variant="h6" color="inherit" align="left">
                <b>Data de criação</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="left">
                <TextField
                  value={ getDate(currentUser?.date_joined || "") }
                  label="Data de criação"
                  disabled
                  variant="outlined"
                  fullWidth
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.row}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Button
                data-testid="btn-click"
                onClick={() => setOpen(true)}
                fullWidth
                disableRipple
                disabled={open}
                className={classes.submit}
              >
                Alterar senha
              </Button>
            </Grid>
          </Grid>
          
          { open && 
            <Grid container justifyContent="center">
              <Grid item xs="auto">
                <Form ref={formRef} className={classes.form} onSubmit={onSubmit}>
                  <Input
                    variant="filled"
                    margin="normal"
                    fullWidth
                    name="current_password"
                    label="Senha atual"
                    type={showCurrentPassword ? 'text' : 'password'}
                    id="current_password"
                    autoComplete="current-password"
                    className={classes.input}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Visualizar" aria-label="show">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-testid="toggle-password"
                              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                            >
                              {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Input
                    variant="filled"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Senha"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    className={classes.input}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Visualizar" aria-label="show">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-testid="toggle-password"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Input
                    variant="filled"
                    margin="normal"
                    fullWidth
                    name="confirmPassword"
                    label="Confirme a senha"
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    autoComplete="current-password"
                    className={classes.input}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Visualizar" aria-label="show">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-testid="toggle-confirm-password"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    data-testid="btn-click"
                    type="submit"
                    fullWidth
                    disableRipple
                    className={classes.submit}
                  >
                    Enviar
                  </Button>
                </Form>
              </Grid>
            </Grid>
          }
        </CardContent>
      </Card>
      {loading && <Loader />}
    </Container>
  );
}
