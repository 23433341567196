import { IExtractionValidation } from '../interfaces/ExtractionValidation';
import { ProjectUserOperator } from '../interfaces/Operator';
import { CrudService } from './api/CrudService';

class ExtractionValidationService extends CrudService<ProjectUserOperator> {
  protected baseUrl(): string {
    return 'projectuseroperator';
  }

  apiData: IExtractionValidation = {};
  parsedData: IExtractionValidation | undefined;

  get frontData() {
    return this.parsedData;
  }

  set apiDataSet(data: IExtractionValidation) {
    this.apiData = data;
    this.parseToFront();
  }

  parseToFront() {
    const dataParsed: IExtractionValidation = {};
    Object.entries(this.apiData).forEach(([key, value]) => {
      if (key.includes('value') || key.includes('bool')) {
        dataParsed[this.parsedString(key)] = value;
      }
    });
    this.parsedData = dataParsed;
    return this.parsedData;
  }

  parseToBack(editedData: IExtractionValidation) {
    const apiData = this.apiData;
    for (const keyEdited in editedData) {
      for (const keyApiData in apiData) {
        if (this.parsedString(keyApiData) === keyEdited) {
          apiData[keyApiData] = editedData[keyEdited];
        }
      }
    }
    return apiData;
  }

  parsedString(string: string) {
    return string
      .replace('.value', '')
      .replaceAll('.', '')
      .replaceAll(/.\[[0-9]+\]/g, '');
  }
}

export default new ExtractionValidationService();
