import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {},
    buttonForm: {
      marginTop: 10,
    },
  }),
);

export default useStyles;
