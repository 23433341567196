import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Project } from '../../../interfaces/Project';
import { useLoader } from '../../../contexts/LoaderContext';
import ProjectUserOperatorService from '../../../services/ProjectUserOperatorService';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ProjectUserOperator } from '../../../interfaces/Operator';
import TaskService from '../../../services/TaskService';
import { StatusEnum } from '../../../consts/Status';
import { AmountTasksProjectUser, Task } from '../../../interfaces/Task';
import { useUserOperator } from '../../../contexts/UserOperatorContext';
import { Projects } from '../../admin';

const useStyles = makeStyles({
  projects: {
    marginTop: 25,
  },
  card: {
    minWidth: 372,
    padding: 10,
  },
  cardButtons: {
    justifyContent: 'center'
  },
  title: {
    margin: 0,
  },
  buttonOperations: {
    marginLeft: 5
  }
});

interface InitialProps {
  selectProject(project: Project, operation: number): void;
  setLoadItems(loadData: () => void): void;
}

export default function Initial({ selectProject, setLoadItems }: InitialProps): JSX.Element {
  const classes = useStyles();
  const [projects, setProjects] = useState<Project[]>([]);
  const [amountTasksProjectsUser, setAmountTasksProjectsUser] = useState<Array<AmountTasksProjectUser>>([]);
  const { changeTask, changeOperation, changeDataProject } = useUserOperator();
  const { currentUser } = useAuthContext();
  const { changeLoading } = useLoader();

  useEffect(() => {
    loadData();
    setLoadItems(() => loadData);
  }, []);

  const loadData = async () => {
    changeLoading(true);
    ProjectUserOperatorService.list({
      useroperator: currentUser?.id
    }).subscribe(
      response => {
        const dataProject: Project[] = [] as Project[];
        response.forEach((item: ProjectUserOperator) => {
          if (item.operations.length > 0) {
            dataProject.push({
              ...item.project_data,
              operations: item.operations.filter(element => item.project_data.operations.includes(element)),
              system_data: item.system_data,
            })
          }
        });
        setProjects(dataProject);
        loadTasksInProcessing(dataProject);
        changeLoading(false);
      }, error => {
        changeLoading(false);
        console.log('Error in OperatorUser Initial: ', error);
        return error;
      }
    )
  };

  const loadTasksInProcessing = (dataProject: Project[]) => {
    const params = { status: StatusEnum.PROCESSANDO };
    TaskService.list(params).subscribe(
      (response: Array<Task>) => {
        const firstTaskList = response[0];
        if (firstTaskList) {
          const project = dataProject.find(element => element?.id === firstTaskList?.project);
          if (project) {
            changeOperation(firstTaskList.operation);
            changeDataProject(project);
          }
          changeTask(firstTaskList);
        } else {
          loadAmountTasksProject();
        }
      }
    )
  }

  const loadAmountTasksProject = () => {
    ProjectUserOperatorService.getAmountTasksProjectsUser().subscribe(
      (response) => {
        setAmountTasksProjectsUser(response);
      }, error => {
        console.log(error);
        return error;
      }
    )
  }

  const handleProject = (project: Project, operation: number) => {
    selectProject(project, operation);
  };

  return (
    <Grid id="projects" className={classes.projects}>
      <Projects 
        projects={projects} 
        amountOperation={amountTasksProjectsUser} 
        handleProject={handleProject} 
        loadData={loadData}
      />
    </Grid>
  );
}
