import React, { useEffect, useState } from 'react';
import { Dialog, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from '../../../../../components/TabPanel';
import EditDataOperador from './EditDataOperador';
import EditTasksOperator from './EditTasksOperator';
import OperatorService from '../../../../../services/OperatorService';
import ValidationService from '../../../../../services/ValidationService';
import { useLoader } from '../../../../../contexts/LoaderContext';
import { useOperator } from '../../OperatorContext';
import ProjectUserOperatorService from '../../../../../services/ProjectUserOperatorService';
import { DataTasks } from '../DefineTasks/interfaces';
import SystemService from '../../../../../services/SystemService';
import { OperationsPermissionProject, Operator } from '../../../../../interfaces/Operator';

interface EditrOperatorProps {
  id: string;
  open: boolean;
  handleClose: () => void;
}

const INDEX_TAB_DATA = 0;
const INDEX_TAB_ACTIVITIES = 1;

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function EditOperator(props: EditrOperatorProps) {
  const { open, handleClose, id } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [currentIndexTab, setCurrentIndexTab] = React.useState(0);
  const { triggerAlertMessage, changeLoading, loading } = useLoader();
  const { changeInitialDataTask, changeCurrentProjectOperationUser } = useOperator();
  const [dataTaskOperator, setDataTaskOperator] = useState<DataTasks>({} as DataTasks);
  const [dataOperator, setDataOperator] = useState({} as Operator);

  useEffect(() => {
    setDataOperator({} as Operator);

    if (open) {
      setCurrentIndexTab(0);
      loadSystemTasks();
      getDataOperator();
    }
  }, [open]);

  const handleChange = (event: unknown, newIndex: number) => {
    setCurrentIndexTab(newIndex);
  };

  const handleChangeIndex = (index: number) => {
    setCurrentIndexTab(index);
  };

  const loadSystemTasks = () => {
    changeLoading(true);
    SystemService.loadSystemsTasks().subscribe(response => {
      changeInitialDataTask(response);
      loadTasksUserOperator(response);
      changeLoading(false);
    });
  }

  const loadTasksUserOperator = (dataTask: DataTasks) => {
    ProjectUserOperatorService.getTasksOperators({
      useroperator: id
    }).subscribe(
      (response: Array<OperationsPermissionProject>) => {
        changeCurrentProjectOperationUser(response);
        ProjectUserOperatorService.defineTasksUserOperator(dataTask, setDataTaskOperator, response);
      }
    )
  }

  const getDataOperator = () => {
    setDataOperator({} as Operator);
    changeLoading(true);
    OperatorService.search(id).subscribe(
      operator => {
        setDataOperator(operator);
        changeLoading(false);
      },
      error => {
        changeLoading(false);
        handleClose();
        ValidationService.validateBackEndAlert(error, triggerAlertMessage);
      },
    );
  };

  return !loading ? (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={currentIndexTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Dados" {...a11yProps(0)} />
            <Tab label="Atividades" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <DialogContent>
          <Typography variant="h6" style={{ textAlign: 'center', marginBottom: 10, marginTop: 20 }}>
            Editar Operador {dataOperator.first_name} {dataOperator.last_name}
          </Typography>

          {dataOperator.id && (
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={currentIndexTab}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={currentIndexTab} index={INDEX_TAB_DATA} dir={theme.direction}>
                <EditDataOperador data={dataOperator} handleClose={handleClose} />
              </TabPanel>

              <TabPanel value={currentIndexTab} index={INDEX_TAB_ACTIVITIES} dir={theme.direction}>
                <EditTasksOperator
                  id={dataOperator.id}
                  data={dataTaskOperator}
                  handleClose={handleClose} />
              </TabPanel>
            </SwipeableViews>
          )}
        </DialogContent>
      </div>
    </Dialog>
  ) : (
    <></>
  );
}
