export const EnumOperations = [
    'Classificação',
    'Extração',
    'Validação',
    'Extração Validação'
]

export const EnumOperationsValues = {
    'Classificação': 1,
    'Extração': 2,
    'Validação': 3,
    'Extração Validação': 4
}