import { Observable } from 'rxjs';
import { UserResponse } from '../interfaces/User';
import { ResetData } from '../pages/Reset/Reset';
import { ChangeData } from '../pages/Account/Account';
import { getUrl } from '../util/Helpers';
import { HttpClient } from './api/HttpClient';

class ResetService<T> {
  protected baseUrl(): string {
    return 'user';
  }

  request(data: ResetData): Observable<UserResponse> {
    return HttpClient.post(getUrl(this.baseUrl(), 'request_reset_password/'), data, {}, true);
  }

  reset(data: ResetData): Observable<UserResponse> {
    return HttpClient.post(getUrl(this.baseUrl(), 'reset_password/'), data, {}, true);
  }

  change(data: ChangeData): Observable<UserResponse> {
    return HttpClient.post(getUrl(this.baseUrl(), 'change_password/'), data, {}, true);
  }
}

export default new ResetService();
