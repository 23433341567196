import React, { createContext, useContext, useEffect, useState } from 'react';
import { StatusEnum } from '../consts/Status';
import { DataForm } from '../interfaces/DataForm';
import { Project } from '../interfaces/Project';
import { SchemaModel } from '../interfaces/Schema';
import { Task } from '../interfaces/Task';
import TaskService from '../services/TaskService';
import { useLoader } from './LoaderContext';

interface UserOperatorContextData {
  project: Project | null;
  changeDataProject(project: Project | null): void;
  dataForm?: DataForm | null;
  changeDataForm(data: DataForm | null): void;
  schema: SchemaModel | null;
  changeDataSchema(data: SchemaModel | null): void;
  operation: number | null;
  changeOperation(operation: number | null): void;
  task: Task;
  changeTask(data: Task): void;
  documents: Array<string>;
  loadTask(): void;
}

const UserOperatorContext = createContext<UserOperatorContextData>({} as UserOperatorContextData);

const UserOperatorProvider: React.FC = ({ children }) => {

  const [project, setProject] = useState<Project | null>(null);
  const [dataForm, setDataForm] = useState<DataForm | null>(null);
  const [schema, setSchema] = useState<SchemaModel | null>(null);
  const [operation, setOperation] = useState<number | null>(null);
  const [task, setTask] = useState<Task>({} as Task);
  const [documents, setDocuments] = useState<Array<string>>([]);
  const { triggerAlertMessage, changeLoading } = useLoader();

  useEffect(() => {
    loadDocuments();
  }, [task]);

  const changeTask = (data: Task) => {
    setTask(data);
  }

  const changeDataProject = (project: Project | null): void => {
    setProject(project);
  };

  async function changeDataForm(data: DataForm | null) {
    setDataForm(data);
  }

  const changeDataSchema = (data: SchemaModel | null) => {
    setSchema(data);
  };

  const changeOperation = (operation: number) => {
    setOperation(operation);
  };

  const loadTask = () => {
    changeLoading(true);
    const params = { project: project?.id, operation };
    TaskService.list(params).subscribe(
      (response: Array<Task>) => {
        if (response) {
          const firstTaskList = response[0];
          if (firstTaskList && firstTaskList.status === StatusEnum.DISPONIVEL) {
            changeTask(firstTaskList);
            workOn(firstTaskList.id);
          } else {
            changeTask({} as Task);
          }
        }
        changeLoading(false);
      }, error => {
        changeLoading(false);
        return error;
      }
    )
  }

  const loadDocuments = () => {
    if (task?.id) {
      const listDocuments = task.filesystem_files.map(file => {
        return `${task.filesystem_path.replace('/opt', '')}/${file}`
      });
      setDocuments(listDocuments);
    } else {
      setDocuments([]);
    }
  }

  const workOn = (id: string) => {
    TaskService.workOn(id).subscribe(
      response => {
        return true;
      }, error => {
        triggerAlertMessage({
          type: 'error',
          msg: 'Não foi possível realizar esta tarefa'
        });
        return error;
      }
    )
  }

  const valueData = {
    project,
    dataForm,
    schema,
    operation,
    task,
    documents,
    changeDataProject,
    changeDataForm,
    changeDataSchema,
    changeOperation,
    changeTask,
    loadTask
  };

  return <UserOperatorContext.Provider value={valueData}>{children}</UserOperatorContext.Provider>;
};

function useUserOperator() {
  const context = useContext(UserOperatorContext);

  if (!context) {
    throw new Error('useUserOperator must be used within an UserOperatorProvider');
  }

  return context;
}

export { UserOperatorContext, UserOperatorProvider, useUserOperator };
