import React from 'react';
import './App.css';
import AlertMessage from './components/AlertMessage';
import { LoaderProvider } from './contexts/LoaderContext';
import { AuthProvider } from './contexts/AuthContext';
import Routes from './Routes';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { colors } from './variables/Colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
    error: {
      main: colors.danger,
    },
    warning: {
      main: colors.warning,
    },
    info: {
      main: colors.info,
    },
    success: {
      main: colors.success,
    },
  },
});

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <LoaderProvider>
        <AuthProvider>
          <AlertMessage />
          <Routes />
        </AuthProvider>
      </LoaderProvider>
    </ThemeProvider>
  );
}

export default App;
