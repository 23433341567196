import React, { useEffect, useRef, useState } from 'react';
import useStyles from './useStyles';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import ValidationService from '../../services/ValidationService';

import Input from '../Input';
import { Button } from '@material-ui/core';
import SelectNuveo from '../SelectNuveo';
import RadioInputNuveoBool from '../RadioInputNuveoBool';
import { MessageValidation } from '../../util/MessageValidation';
import { getLabel } from '../../util/Helpers';
import { FormHandles } from '@unform/core';
import { useUserOperator } from '../../contexts/UserOperatorContext';
import { DataSchemaModel } from '../../interfaces/Schema';
import { FieldsModel } from '../../interfaces/DataForm';
import { SuccessStatus } from '../../interfaces/Task';
import { Props } from './props';

const FormBuilder = (props: Props): JSX.Element => {
  const { data, metadata, actionForm, rejectTask, previousAction, displayReject } = props;
  const { dataForm, changeDataForm } = useUserOperator();
  const [observation, setObservation] = useState("");
  const classes = useStyles();
  const formRef = useRef<FormHandles>(null);

  const onSubmit = (data: DataSchemaModel): void => {
    validateFields().then(response => {
      if (response) {
        if (previousAction) previousAction();
        actionForm(data, observation, SuccessStatus.UNDEFINED, []);
      }
    });
  };

  const onReject = (): void => {
    if (previousAction) previousAction();
    rejectTask(data);
  };

  useEffect(() => {
    if (dataForm) formRef?.current?.setData(dataForm);
  }, []);

  const handleChange = (): void => {
    const data = formRef?.current?.getData();
    if (data) changeDataForm(data);
  };

  const validateFields = () => {
    const rules: { [key: string]: string | unknown } = {};

    if (data) {
      data.forEach((item: DataSchemaModel) => {
        if (item.required) rules[item.name] = Yup.string().required(MessageValidation.required);
      });
    }

    return ValidationService.validate(formRef?.current, rules);
  };

  const fields: FieldsModel = {
    text(item: DataSchemaModel) {
      return (
        <Input
          key={item.label}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          type={item.type}
          required={item.required}
          fullWidth
          label={getLabel(item.label)}
          name={item.name}
          autoComplete="email"
          autoFocus
        />
      );
    },
    number(item: DataSchemaModel) {
      return this.text(item);
    },
    select(item: DataSchemaModel) {
      return (
        <SelectNuveo
          key={item.label}
          onChange={handleChange}
          name={item.name}
          label={getLabel(item.label)}
          items={item?.options ? item.options : []}
        />
      );
    },
    bool(item: DataSchemaModel) {
      return (
        <RadioInputNuveoBool 
          key={item.label} 
          name={item.name} 
          label={getLabel(item.label)}
          onChange={handleChange}
        />
      );
    },
  };

  return (
    <div>
      {data && (
        <Form
          ref={formRef}
          className={classes.form}
          initialData={dataForm || undefined}
          onSubmit={onSubmit}
        >
          {data.map((item: DataSchemaModel) => {
            return fields[item.type as keyof FieldsModel](item);
          })}

          <Input
            key="observation"
            onChange={(e) => setObservation(e)}
            variant="outlined"
            margin="normal"
            fullWidth
            label="Observação"
            value={observation}
            name="Observação"
          />

          {
            metadata? <>
              <p><b>Metadata</b></p>
              <p style={{ textAlign: "left" }}><pre >{ JSON.stringify(metadata, null, 2) }</pre></p>
            </> : null
          }

          <Button
            data-testid="submit-and-next"
            size="large"
            color="primary"
            className={classes.buttonForm}
            variant="contained"
            type="submit"
            fullWidth
          >
            Enviar e Próximo
          </Button>
          {
            displayReject &&
            <Button
              data-dataormid="submit-and-next"
              size="large"
              className={classes.buttonForm}
              style={{ backgroundColor: 'red' }}
              onClick={() => onReject()}
              color="secondary"
              variant="contained"
              fullWidth
            >
              Recusar
            </Button>
          }

        </Form>
      )}
    </div>
  );
};

export default FormBuilder;
