import React, { createContext, useContext, useState } from 'react';
import jwt_decode, { JwtPayload } from "jwt-decode";
import { User, UserResponse } from '../interfaces/User';
import { generateJWT } from '../util/Helpers';

interface AuthContextData {
  isLoggedIn: boolean;
  accessToken: string | null;
  currentUser: User | null;
  changeIsLoggedIn(value: boolean): void;
  setLogin(data: UserResponse): void;
  setLogout(): void;
}

interface JwtPayloadUser {
  sub: User | null
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {

  function getCurrentUser(): User | null {
    const userStorage = (localStorage.getItem('currentUser') || '')
    if (!userStorage) return null;
    const jwt: JwtPayloadUser = jwt_decode(userStorage);
    const user: User | null = jwt.sub;
    return user;
  }

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(localStorage.getItem('accessToken') ? true : false);
  const [accessToken, setAccessToken] = useState<string>(localStorage.getItem('accessToken') || '');
  const [currentUser, setCurrentUser] = useState<User | null>(getCurrentUser());

  async function changeIsLoggedIn(value: boolean) {
    setIsLoggedIn(value);
  }

  function setLogin(data: UserResponse) {

    const userInfo: User = {
      username: data.username,
      date_joined: data.date_joined,
      email: data.email,
      firstName: data.first_name,
      lastName: data.last_name,
      is_admin: data.is_admin,
      id: data.user_id,
    };

    localStorage.setItem('currentUser', generateJWT(userInfo));
    localStorage.setItem('accessToken', data.token);

    setAccessToken(data.token);
    setCurrentUser(userInfo);
    setIsLoggedIn(true);
  }

  function setLogout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUser');
    setAccessToken('');
    setCurrentUser(null);
    setIsLoggedIn(false);
  }

  const valueData = {
    isLoggedIn,
    accessToken,
    currentUser,
    changeIsLoggedIn,
    setLogin,
    setLogout,
  };

  return <AuthContext.Provider value={valueData}>{children}</AuthContext.Provider>;
};

function useAuthContext() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useLoader must be used within an LoaderProvider');
  }

  return context;
}

export { AuthProvider, useAuthContext };
