import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpConfig } from '../../interfaces/HttpConfig';
import { Observable } from 'rxjs';
import { AxiosRequestDispatcher } from './AxiosRequestDispatcher';

import * as dotenv from 'dotenv';
import { Type } from 'typescript';
dotenv.config({
  path: process.env.NODE_ENV === 'development' ? '.env.development.local' : '.env',
});

type ResponseFunction = (response: AxiosResponse) => AxiosResponse;
type RequestFunction = (config: AxiosRequestConfig) => AxiosRequestConfig;
type ErrorFunction = (e: Type) => Type;

class HttpClient {
  private http: AxiosInstance;
  protected external_route = false;

  constructor() {
    this.http = Axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 60000,
    });
    this.setDefaultRequestInterceptors();
    this.setDefaultResponseInterceptors();
  }

  private setDefaultRequestInterceptors() {
    this.http.interceptors.request.use(config => {
      config.headers = {
        'Content-Type': 'application/json',
        ...config.headers,
      };
      if (!this.external_route) {
        config.headers.Authorization = `Token ${localStorage.getItem('accessToken')}`;
      }
      return config;
    });
  }

  private setDefaultResponseInterceptors() {
    this.http.interceptors.response.use(
      response => response,
      (e: AxiosError) => {
        if (e.isAxiosError && e.response && e.response.data) {
          if (e.response.status === 503) window.location.href = '503';
          throw e.response;
        }
      },
    );
  }

  interceptRequest(onFulfilled: RequestFunction, onRejected?: ErrorFunction) {
    this.http.interceptors.request.use(onFulfilled, onRejected);
    return this;
  }

  interceptResponse(onFulfilled: ResponseFunction, onRejected?: ErrorFunction) {
    this.http.interceptors.response.use(onFulfilled, onRejected);
    return this;
  }

  formatUrl(url: string) {
    const lastWord = url.substring(url.length - 1);
    return lastWord == '/' ? url : url + '/';
  }

  get<T>(url: string, config?: HttpConfig<Partial<T>>, external_route = false): Observable<T> {
    this.external_route = external_route;
    url = this.formatUrl(url);
    return new AxiosRequestDispatcher(this.http, {
      url,
      method: 'get',
      config,
    });
  }

  post<Type, R>(
    url: string,
    data: Type,
    config?: HttpConfig,
    external_route = false,
  ): Observable<R> {
    this.external_route = external_route;
    url = this.formatUrl(url);
    return new AxiosRequestDispatcher(this.http, {
      url,
      method: 'post',
      data,
      config,
    });
  }

  put<T, R = T>(
    url: string,
    data: Partial<T>,
    config?: HttpConfig,
    external_route = false,
  ): Observable<R> {
    this.external_route = external_route;
    url = this.formatUrl(url);
    return new AxiosRequestDispatcher(this.http, {
      url,
      method: 'put',
      data,
      config,
    });
  }

  patch<T, R = T>(
    url: string,
    data: Partial<T>,
    config?: HttpConfig,
    external_route = false,
  ): Observable<R> {
    this.external_route = external_route;
    url = this.formatUrl(url);
    return new AxiosRequestDispatcher(this.http, {
      url,
      method: 'patch',
      data,
      config,
    });
  }

  delete<R = Type>(url: string, config?: HttpConfig, external_route = false): Observable<R> {
    this.external_route = external_route;
    url = this.formatUrl(url);
    return new AxiosRequestDispatcher(this.http, {
      url,
      method: 'delete',
      config,
    });
  }
}

const instance = new HttpClient();
export { instance as HttpClient };
