import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { colors } from '../variables/Colors';

interface NoDataFoundProps {
  text?: string;
  type?: 'info' | 'warning' | 'error' | 'success';
  variant?: 'outlined' | 'filled' | 'standard';
}

export default function NoDataFound(props: NoDataFoundProps): JSX.Element {

  const { text, type, variant } = props;

  return (
    <Alert
      variant={variant ? variant : 'filled'}
      style={{
        backgroundColor: variant !== 'outlined' ? colors.primary : '',
        marginTop: 5,
      }}
      severity={type ? type : 'info'}
    >
      <Typography>{text ? text : 'Nenhum dado encontrado !'}</Typography>
    </Alert>
  );
}
