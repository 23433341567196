export const colors = {
  primary: '#123F54',
  secondary: '#36AEE0',
  white: '#F8F8FF',
  backgroundGrey: '#f4f5f7',
  lightPrimary: '#5c2b84b8',
  opacityPrimary: '#e4ddec',
  lightPrimary400: '#8663a4',
  danger: '#E05241',
  grey: '#9E9E9E',
  warning: '#E05241',
  info: '#1C6486',
  success: '#70C5C5',
};
