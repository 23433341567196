import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Input from '../../components/Input';
import Container from '@material-ui/core/Container';
import { MessageValidation } from '../../util/MessageValidation';
import ValidationService from '../../services/ValidationService';

import { colors } from '../../variables/Colors';
import ResetService from '../../services/ResetService';
import { FormHandles } from '@unform/core';
import { useLoader } from '../../contexts/LoaderContext';
import Loader from '../../components/Loader';
import { AlertMessage } from '../../interfaces/AlertMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    height: '100vh',
    backgroundColor: colors.primary,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '272px',
    marginBottom: '20px',
  },
  form: {
    width: '442px',
    marginTop: theme.spacing(1),
  },
  input: {
    backgroundColor: colors.white,
    borderRadius: '5px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: colors.secondary,
    color: colors.white,
    fontWeight: 600,
    fontSize: '24px',

    '&:hover': {
      backgroundColor: '#058ac1',
    },
  },
}));

export interface ResetData {
  email: string;
  code: string;
  password: string;
}

export default function Reset(): JSX.Element {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [requested, setRequested] = useState(false);
  const { loading, changeLoading, triggerAlertMessage } = useLoader();
  const { isLoggedIn } = useAuthContext();

  useEffect(() => {
    isLoggedIn && history.push('/');
  });

  const history = useHistory();

  const formRequestRef = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);

  const onSubmit = (data: ResetData): void => {
    validateFields().then(response => {
      if (response) {
        changeLoading(true);
        ResetService.reset(data).subscribe(
          () => {
            changeLoading(false);
            const msgData: AlertMessage = {
              msg: 'Senha alterada com sucesso.',
              type: 'success',
            };
            triggerAlertMessage(msgData);
            history.push('/login');
          },
          err => {
            const errorData: AlertMessage = {
              msg: 'Ocorreu um erro inesperado ao alterar a senha. Tente novamente.',
              type: 'error',
            };

            if (err.status === 400) {
              errorData.msg = 'Não foi possível fazer alterar a senha com as informações fornecidas.';
            }

            triggerAlertMessage(errorData);
            changeLoading(false);
          },
        );
      }
    });
  };

  const onSubmitRequest = (data: ResetData): void => {
    validateFieldsRequest().then(response => {
      if (response) {
        changeLoading(true);
        ResetService.request(data).subscribe(
          () => {
            changeLoading(false);
            setRequested(true);
          },
          err => {
            const errorData: AlertMessage = {
              msg: 'Ocorreu um erro inesperado ao requisitar o código de recuperação. Tente novamente.',
              type: 'error',
            };

            if (err.status === 400) {
              errorData.msg = 'Não foi possível solicitar o código de recuperação.';
            }

            triggerAlertMessage(errorData);
            changeLoading(false);
          },
        );
      }
    });
  };

  const validateFields = (): Promise<boolean> => {
    const rules = {
      code: Yup.string().required(MessageValidation.required),
      password: Yup.string().required(MessageValidation.required).min(8, MessageValidation.min),
      confirmPassword: Yup.string()
        .required(MessageValidation.required)
        .min(8, MessageValidation.min)
        .oneOf([Yup.ref('password'), null], MessageValidation.password),
    };
    return ValidationService.validate(formRef?.current, rules);
  };

  const validateFieldsRequest = (): Promise<boolean> => {
    const rules = {
      email: Yup.string().required(MessageValidation.required),
    };
    return ValidationService.validate(formRequestRef?.current, rules);
  };

  return (
    <Container component="main" className={classes.main}>
      <div className={classes.paper}>
        <div className={classes.logo}>
          <img
            src="https://assets.nuveo.ai/logos/transparent_white.png"
            alt="Nuveo logo"
            width="272"
          />
        </div>

        {
          requested ? 
            <Form ref={formRef} className={classes.form} onSubmit={onSubmit}>
              <Input
                variant="filled"
                margin="normal"
                fullWidth
                disabled
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                className={classes.input}
              />
              <Input
                variant="filled"
                margin="normal"
                fullWidth
                id="code"
                label="Código"
                name="code"
                autoComplete="code"
                autoFocus
                className={classes.input}
              />
              <Input
                variant="filled"
                margin="normal"
                fullWidth
                name="password"
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                className={classes.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Visualizar" aria-label="show">
                        <IconButton
                          aria-label="toggle password visibility"
                          data-testid="toggle-password"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <Input
                variant="filled"
                margin="normal"
                fullWidth
                name="confirmPassword"
                label="Confirme a senha"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                autoComplete="current-password"
                className={classes.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Visualizar" aria-label="show">
                        <IconButton
                          aria-label="toggle password visibility"
                          data-testid="toggle-confirm-password"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                data-testid="btn-click"
                type="submit"
                fullWidth
                disableRipple
                className={classes.submit}
              >
                Alterar senha
              </Button>
            </Form>
          :
            <Form ref={formRequestRef} className={classes.form} onSubmit={onSubmitRequest}>
              <Input
                variant="filled"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                className={classes.input}
              />
              <Button
                data-testid="btn-click"
                type="submit"
                fullWidth
                disableRipple
                className={classes.submit}
              >
                Requisitar código
              </Button>
            </Form>
        }
      </div>
      {loading && <Loader />}
    </Container>
  );
}
